@charset "UTF-8";

html {
	height: 100%;
	overflow-y: scroll;
}

@font-face {
	font-family:DM Sans;src:url(../fonts/DMSans_18pt-Black.ttf) format("ttf");font-weight:900;font-style:normal;font-display:auto;
}

@font-face {
	font-family:DM Sans;src:url(../fonts/DMSans_18pt-Bold.ttf) format("ttf");font-weight:700;font-style:normal;font-display:auto;
}

@font-face {
	font-family:DM Sans;src:url(../fonts/DMSans_18pt-BlackItalic.ttf) format("ttf");font-weight:900;font-style:italic;font-display:auto;
}

@font-face {
	font-family:DM Sans;src:url(../fonts/DMSans_18pt-Regular.ttf) format("ttf");font-weight:400;font-style:normal;font-display:auto;
}

@font-face {
	font-family:DM Sans;src:url(../fonts/DMSans_18pt-ThinItalic.ttf) format("ttf");font-weight:100;font-style:italic;font-display:auto;
}

@font-face {
	font-family:DM Sans;src:url(../fonts/DMSans_18pt-Medium.ttf) format("ttf");font-weight:500;font-style:normal;font-display:auto;
}

@font-face {
	font-family:DM Sans;src:url(../fonts/DMSans_18pt-MediumItalic.ttf) format("ttf");font-weight:500;font-style:italic;font-display:auto;
}

@font-face {
	font-family:DM Sans;src:url(../fonts/DMSans_18pt-Thin.ttf) format("ttf");font-weight:100;font-style:normal;font-display:auto;
}

@font-face {
	font-family:DM Sans;src:url(../fonts/DMSans_18pt-LightItalic.ttf) format("ttf");font-weight:300;font-style:italic;font-display:auto;
}

@font-face {
	font-family:DM Sans;src:url(../fonts/DMSans_18pt-Italic.ttf) format("ttf");font-weight:400;font-style:italic;font-display:auto;
}

@font-face {
	font-family:DM Sans;src:url(../fonts/DMSans_18pt-BoldItalic.ttf) format("ttf");font-weight:700;font-style:italic;font-display:auto;
}

@font-face {
	font-family:DM Sans;src:url(../fonts/DMSans_18pt-Light.ttf) format("ttf");font-weight:300;font-style:normal;font-display:auto;
}

body,html {
	font-family: "DM Sans";
	color: #000;
	overscroll-behavior-y: none;
}

.mobile-view {
	display: inline!important;
}

h1,h2,h3,h4,h5,h6 {
	font-family: "DM Sans",Verdana!important;
}

#sidebar {
	border-right: 1px solid #f4f6ff;
	outline-style: solid;
	outline-width: 2px;
	outline-color: #e6e8f7;
}

.upload-btn-wrapper {
	position: relative;
	overflow: hidden;
	display: inline-block;
	background-color: #fff;
	border: 1px solid #cdcdcd;
	border-radius: 3px;
	padding: 4px 5px;
	height: 42px!important;
	width: 100%;
}

.upload-btn-wrapper .btnr {
	border: 1px solid #777;
	color: #000;
	background-color: #f4f5f5;
	background-image: -webkit-gradient(linear,left top,left bottom,from(#f4f5f5),to(#dfdddd));
	background-image: -webkit-linear-gradient(top,#f4f5f5,#dfdddd);
	background-image: -moz-linear-gradient(top,#f4f5f5,#dfdddd);
	background-image: -ms-linear-gradient(top,#f4f5f5,#dfdddd);
	background-image: -o-linear-gradient(top,#f4f5f5,#dfdddd);
	background-image: linear-gradient(to bottom,#f4f5f5,#dfdddd);
	padding: 2px 8px;
	border-radius: 1px;
	font-size: 14px;
	position: relative;
}

.upload-btn-wrapper .btnr:hover {
	background-color: #d9dddd;
	background-image: -webkit-gradient(linear,left top,left bottom,from(#d9dddd),to(#c6c3c3));
	background-image: -webkit-linear-gradient(top,#d9dddd,#c6c3c3);
	background-image: -moz-linear-gradient(top,#d9dddd,#c6c3c3);
	background-image: -ms-linear-gradient(top,#d9dddd,#c6c3c3);
	background-image: -o-linear-gradient(top,#d9dddd,#c6c3c3);
	background-image: linear-gradient(to bottom,#d9dddd,#c6c3c3);
}

.upload-btn-wrapper .btnr+span {
	padding: 5px;
	font-weight: 400;
}

.upload-btn-wrapper input[type="file"].absolute-input {
	font-size: 42px;
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0!important;
}

.pos-r {
	position: relative;
}

.chartpdf-button {
	position: absolute;
	right: 5px;
	top: -2px;
}

@media only screen and (min-width:767px) {
	.mobile-view {
		display: none!important;
	}
}

.tablet-view {
	display: none!important;
}

@media only screen and (min-width:767px) {
	.tablet-view {
		display: inline!important;
	}
}

@media only screen and (min-width:991px) {
	#sidebar .nav-link {
		width: 4.5em;
	}
}

#sidebar .nav-link .nav-icon {
	-ms-flex-preferred-size: auto;
	flex-basis: auto;
	width: 4.6em;
}

#sidebar {
	right: auto;
	width: 4em;
	box-orient: vertical;
	-webkit-box-orient: vertical;
	box-direction: normal;
	-webkit-box-direction: normal;
	-ms-flex-flow: column;
	flex-flow: column;
	box-align: start;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: start;
	-webkit-transition: all 250ms ease-in-out;
	transition: all 250ms ease-in-out;
	background-color: #ffffff;
	box-shadow: 0 .125em 1.25em rgba(119,119,119,.2);
}

#sidebar .navbar-toggler {
	height: 60px;
	background-color: #ffffff;
}

@media only screen and (min-width:991px) {
	#sidebar .navbar-toggler {
		height: 63px;
	}
}

.navbar-toggler svg {
	width: 37px;
}

#sidebar .nav-item .nav-link .nav-icon .fa {
	color: #004281;
}

#sidebar .nav-item .nav-link.active .nav-icon .fa {
	color: #fff;
	z-index: 1;
}

.login-form.login-info .form-group input+span {
	position: absolute;
	left: 7px;
	bottom: 10px;
}

.utilicon.username:before {
	content: "\E07F";
}

.fa.password:before {
	content: "\f084";
}

.login-info .btn {
	background-color: #727A06;
}

.welcome-deeproots {
	display: block;
	background-color: #e0f0f1;
	position: absolute;
	width: 100%;
	bottom: 0;
	text-align: center;
	padding: 1.5rem 0 0 0;
	box-sizing: border-box;
}

.welcome-deeproots img {
	width: 155px;
}

.welcome-deeproots h2 {
	font-weight: 300;
	text-transform: none;
	margin: 16px 0 3px 0;
	color: #1d2239;
	font-size: 2.5rem!important;
	font-family: "DM Sans"!important;
}

.peoject-img-list a {
	display: block;
	position: relative;
}

.dp-initiative {
	background-color: #d9eaeb;
	padding: 15px 0;
	font-size: 11px;
	letter-spacing: 1px;
	font-weight: 400;
	margin-top: 30px;
	padding-bottom: 40px;
}

.dp-initiative img {
	width: 85px;
}

.login-screen,.reset-screen {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	background: rgba(255,255,255,.85);
	padding: 30px 20px;
	width: 90%;
	margin: 2% auto 0;
	height: auto!important;
	text-align: right;
	overflow: hidden;
	border-radius: 6px;
}

@media only screen and (min-width:550px) and (max-width:767px) {
	.login-screen,.reset-screen {
		margin: 0 auto;
		padding: 2%;
		width: 90%;
	}

	.login-screen .logo img,.reset-screen .logo img {
		width: 180px;
	}
}

.login-screen h3,.reset-screen h3 {
	text-transform: none;
	color: #1d2239;
	font-weight: 700;
	margin-top: 0;
}

.login-screen p {
	margin-bottom: 10px;
	text-align: center;
	display: block;
	font-size: 13px;
}

.login-screen .form-group label {
	text-align: left;
}

.forgot-body .form-group label {
	text-align: left;
}

.reset-body .form-group label {
	text-align: left;
	display: inline-block;
}

.reset-body .form-group label .small {
	font-size: 11px;
	color: #8d8d8d;
	position: inherit;
	font-style: italic;
}

.reset-body hr {
	margin: 21px 0 12px 0;
	display: block;
	background-color: #30365a;
}

.reset-screen h3.login-header {
	margin-top: 0;
}

.reset-screen {
	margin: 5% auto 0!important;
}

.reset-screen {
	height: auto;
}

.reset-screen .qrscan img {
	width: 120px;
	margin: 0 auto;
}

.bg {
	background-image: url(../images/new-login-bg.webp);
	background-size: 100% 100%;
	background-position: center;
	height: 100vh;
	overflow: hidden;
	background-repeat: no-repeat;
	position: relative;
	z-index: 10;
	width: 65%;
	float: left;
}

.login-right {
	width: 35%;
	float: right;
	position: relative;
}

@media only screen and (min-width:550px) {
	.bg {
		background-size: cover;
	}
}

@media only screen and (min-width:767px) {
	.bg {
		background-image: url(../images/login-bg-plain.webp);
		background-size: 100% 100%;
	}
}

@media only screen and (min-width:1200px) {
	.bg {
		background-image: url(../images/new-login-bg.webp);
		background-size: 100% 100%;
	}
}

#sidebar {
	z-index: 999;
}

.loading_middle {
	z-index: 10;
	position: relative;
	background-color: #1d2239;
	width: 100%;
	color: #fff;
}

.logo img {
	width: 220px;
}

.login-btn {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	background-color: #246f00;
	border-radius: 12px;
	padding: 5px 5px 7px 5px;
	font-size: 14px;
	font-weight: 400;
}

.form-inline .form-group:nth-child(1n) {
	margin-right: 7px;
}

.form-group label {
	width: 100%;
	margin-bottom: .35rem;
}

.nav-item a.nav-link,.nav-item a.nav-link span {
	color: #000;
}

.nav-item:first-child a.nav-link .nav-text-overflow,.nav-item:first-child a.nav-link span {
	color: #fff;
}

.card .card-body .col,.card .card-body .col-1,.card .card-body .col-10,.card .card-body .col-11,.card .card-body .col-12,.card .card-body .col-2,.card .card-body .col-3,.card .card-body .col-4,.card .card-body .col-5,.card .card-body .col-6,.card .card-body .col-7,.card .card-body .col-8,.card .card-body .col-9,.card .card-body .col-auto,.card .card-body .col-lg,.card .card-body .col-lg-1,.card .card-body .col-lg-10,.card .card-body .col-lg-11,.card .card-body .col-lg-12,.card .card-body .col-lg-2,.card .card-body .col-lg-3,.card .card-body .col-lg-4,.card .card-body .col-lg-5,.card .card-body .col-lg-6,.card .card-body .col-lg-7,.card .card-body .col-lg-8,.card .card-body .col-lg-9,.card .card-body .col-lg-auto,.card .card-body .col-md,.card .card-body .col-md-1,.card .card-body .col-md-10,.card .card-body .col-md-11,.card .card-body .col-md-12,.card .card-body .col-md-2,.card .card-body .col-md-3,.card .card-body .col-md-4,.card .card-body .col-md-5,.card .card-body .col-md-6,.card .card-body .col-md-7,.card .card-body .col-md-8,.card .card-body .col-md-9,.card .card-body .col-md-auto,.card .card-body .col-sm,.card .card-body .col-sm-1,.card .card-body .col-sm-10,.card .card-body .col-sm-11,.card .card-body .col-sm-12,.card .card-body .col-sm-2,.card .card-body .col-sm-3,.card .card-body .col-sm-4,.card .card-body .col-sm-5,.card .card-body .col-sm-6,.card .card-body .col-sm-7,.card .card-body .col-sm-8,.card .card-body .col-sm-9,.card .card-body .col-sm-auto,.card .card-body .col-xl,.card .card-body .col-xl-1,.card .card-body .col-xl-10,.card .card-body .col-xl-11,.card .card-body .col-xl-12,.card .card-body .col-xl-2,.card .card-body .col-xl-3,.card .card-body .col-xl-4,.card .card-body .col-xl-5,.card .card-body .col-xl-6,.card .card-body .col-xl-7,.card .card-body .col-xl-8,.card .card-body .col-xl-9,.card .card-body .col-xl-auto {
	float: left;
}

.card.border-gray-25 {
	margin-bottom: 15px;
}

.card-body {
	padding: .25rem;
}

.hide {
	display: none;
}

#sidebar .nav-link.active::before {
	opacity: 1;
	z-index: auto;
}

#sidebar .nav-item a.nav-link.active .nav-text-overflow,#sidebar .nav-item a.nav-link.active span {
	color: #fff;
	z-index: 1;
}

.nav-item:first-child a.nav-link span {
	color: #2d2d2d;
}

.nav-item:first-child a.nav-link .nav-text-overflow {
	color: #404040;
}

.login-screen .form-group input.form-control,.login-screen .form-group select {
	height: 37px!important;
	padding-left: 25px;
	padding-right: 25px;
	font-size: 12px;
}

.login-screen .form-group input.form-control:focus+.separator,.login-screen .form-group select:focus+.separator {
	-webkit-transform: scaleX(1) translateY(-2px);
	transform: scaleX(1) translateY(-2px);
	opacity: .8;
}

.language-changes {
	background-color: #f2f3f9;
	padding: 4px 11px;
	z-index: 99;
	margin: 0 auto;
	display: inline-block;
	width: 145px;
	text-align: center;
	border-radius: 5px;
	border: 1px solid #0078d1;
}

.page-wrapper {
	min-height: auto!important;
}

.login-screen .separator {
	height: 3px;
	width: 100%;
	background: #0b6623;
	display: block;
	-webkit-transform: scaleX(0) translateY(-2px);
	transform: scaleX(0) translateY(-2px);
	-webkit-transform-origin: 50%;
	transform-origin: 50%;
	opacity: 0;
	-webkit-transition: all 0.15s linear;
	transition: all 0.15s linear;
}

* :focus {
	outline-color: transparent;
	outline-style: none;
}

.forgot-pwd,.reset-pwd {
	color: #000;
}

.forgot,.reset {
	display: none;
}

.forgot .forgot-body a,.forgot .reset-body a,.reset .forgot-body a,.reset .reset-body a {
	margin: 0 auto;
}

.forgot .login-info h4 {
	padding: 20px;
}

.forgot .login-info p {
	padding: 0 20px 20px 20px;
}

.clear-btn {
	border-radius: 12px;
}

.width-page-center {
	margin: 0 auto;
}

body {
	padding-top: 0;
}

.inner-addon {
	position: relative;
}

.inner-addon .glyphicon {
	position: absolute;
	padding: 10px;
	pointer-events: none;
}

.left-addon .glyphicon {
	left: 0;
}

.right-addon .glyphicon {
	right: 0;
}

.left-addon input {
	padding-left: 30px;
}

.right-addon input {
	padding-right: 30px;
}

.header {
	height: 57px;
	background-image: url(../images/green-bg.png);
	width: 100%;
	position: fixed;
	top: 0;
	z-index: 999;
}

.header-overlay {
	width: 100%;
	height: 57px;
	background: #000;
	opacity: 0.6;
	position: absolute;
	top: 0;
	left: 0;
}

.header .user-dropdown .user-name {
	text-transform: capitalize;
	display: block;
	color: #fff;
	white-space: nowrap;
}

.project-header {
	background-color: #fff;
	padding: 6px 15px 6px 79px;
	position: fixed;
	width: 100%;
	top: 58px;
	left: 0;
	right: 0;
	z-index: 9;
	display: flex;
}

.project-header h3 {
	color: #fff;
	display: inline-block;
	top: 2px;
	position: relative;
	margin: 0 19px 0 0;
	font-weight: 700;
}

.project-header .btn {
	background-color: transparent;
	border: 1.5px solid #fff;
	padding: .3rem .9rem;
	font-weight: 700;
	top: -3px;
	text-transform: uppercase;
	margin-left: 20px;
}

.project-header .btn:hover {
	color: #727A06;
	background-color: #fff;
	border-color: #fff;
}

.project-header .search {
	float: right;
	margin: 0;
	width: 290px;
}

.search .input-group.input-group-right {
	margin-bottom: 0;
}

.project-header .form-control {
	background-color: transparent;
	color: #fff;
	padding: .4rem 0.75em!important;
	text-transform: capitalize;
	font-weight: 500;
}

.project-header .form-control::placeholder {
	color: #fff;
}

.project-header .form-group-sm .input-group .input-group-icon {
	background-color: transparent;
	padding: .8rem .1em .3rem 0.1em!important;
}

.project-header .search.form-group-sm .input-group .input-group-icon .input-icon {
	border: none;
	color: #fff;
}

.project-header .search.form-group-sm .input-group .input-group-icon .input-icon:hover {
	background-color: transparent!important;
}

.form-group-sm .form-control.focus,.form-group-sm .form-control.focus~.input-group-icon,.form-group-sm .form-control:focus~.input-group-icon,.project-header .form-group-sm .form-control:focus {
	border-color: #fff;
}

.project-list {
	/* background: #f5f6fc;
	background: -moz-linear-gradient(-45deg,#f5f6fc 0,#dfe2f8 100%);
	background: -webkit-gradient(left top,right bottom,color-stop(0,#f5f6fc),color-stop(100%,#dfe2f8));
	background: -webkit-linear-gradient(-45deg,#f5f6fc 0,#dfe2f8 100%);
	background: -o-linear-gradient(-45deg,#f5f6fc 0,#dfe2f8 100%);
	background: -ms-linear-gradient(-45deg,#f5f6fc 0,#dfe2f8 100%);
	background: linear-gradient(135deg,#f5f6fc 0,#dfe2f8 100%); */
	padding: 20px 10px 20px 64px;
	margin-bottom: 10px;
	position: relative;
}

.project-title {
	background-color: #fff;
	position: absolute;
	bottom: 0;
	left: 0;
	text-align: center;
	padding: 4px;
	font-size: .9rem;
	font-weight: 500;
	color: #5f5f5f;
	font-family: "DM Sans"!important;
	width: 100%;
	display: block;
}

.peoject-img-list img {
	width: 100%;
	min-height: 185px;
}

.service-image {
	cursor: pointer;
	/* background-color: #307297; */
	padding: 12px 9px;
	border-radius: 4px;
	text-align: center;
	display: inline-block;
	margin-right: 15px;
	position: relative;
	width: 112px;
	transition: background-color 0.3s ease-in-out;
}

.white-circle {
	padding: 5px 7px;
	height: 60px;
	width: 60px;
	background-color: #fff;
	border-radius: 50%;
	display: inline-block;
	position: relative;
}
.white-circle img {
	width:50%;
	position: absolute;
	top:50%;
	left:50%;
	transform: translate(-50%, -50%);
}

.project-para h3 {
	text-transform: none;
	color: #1d2239;
	font-size: 1rem!important;
	font-weight: 700;
	margin: 0;
}

.service-image:hover {
	background-color: #65b35c;
}

.service-image .utilicon-check-circle {
	position: absolute;
	right: 10px;
	top: 10px;
	color: #fff;
}

.service-name {
	display: block;
	color: #fff;
	line-height: 16px;
	margin-top: 11px;
	font-size: .9rem;
	white-space: normal;
	min-width: 60px;
}

.services-list {
	white-space: nowrap;
	overflow-x: auto;
	display: flex;
	justify-content: center;
}

.services-available h3 {
	text-transform: none;
	color: #1d2239;
	font-size: 1rem!important;
	font-weight: 700;
	margin: 0;
}

.project-right-area {
	float: right;
	width: 100%;
	padding: 1%;
	position: relative;
}

.peoject-img-list {
	position: relative;
	width: 22%;
	float: left;
	height: 206px;
	display: none;
}

.project-table-data {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	background-color: #f5f7ff;
	width: 100%;
	overflow-y: auto;
	padding: 10px;
	font-size: 12px;
	height: 100%;
}

.project-table-data strong {
	font-weight: 700;
}

.project-table-data table.table {
	margin-bottom: 0;
	border: none;
}

.project-table-data .table tbody tr td {
	padding: .31rem 0.6rem!important;
	font-size: .7rem;
	line-height: 16px;
	border-right: 1px solid #ddd;
}

.project-para {
	height: 70px;
	overflow-y: auto;
	margin-bottom: 10px;
}

.project-para p {
	margin-bottom: 5px;
}

.project-header nav.breadcrumb .breadcrumb-item.active {
	color: #004372;
}

.project-header nav.breadcrumb {
	margin: 0;
	padding: 0;
	display: inline-block;
	text-transform: uppercase;
}

.project-header nav.breadcrumb li a {
	color: #004372;
	cursor: pointer;
}

.project-header nav.breadcrumb li a:hover {
	text-decoration: underline;
}

.project-header nav.breadcrumb li {
	color: #004372;
	font-weight: 400;
}

.project-header nav.breadcrumb .breadcrumb-item+.breadcrumb-item:before {
	color: #004372;
}

.project-header nav.breadcrumb ol {
	margin: 0;
	padding: 0;
	font-weight: 700;
	opacity: .9;
}

.message-text p {
	font-size: 2.4rem;
	float: left;
	width: 92%;
	line-height: normal;
}

.message-text p i {
	color: #f67a55;
	position: relative;
	top: 9px;
}

div.content-wrap {
	margin-top: 110px!important;
	padding-bottom: 40px;
}

div.view-profile {
	margin-top: 150px!important;
}

.form-box {
	padding: 10px 20px;
}

.form-design {
	border: 1px solid #cdcdcd;
	border-radius: 4px;
	margin: 0 0 20px 0;
	padding-bottom: 40px;
}

.form-design .collapse {
	width: 100%;
	overflow: hidden;
}

.form-design .group-title {
	font-size: 1.3rem;
	color: #292929;
	display: block;
	font-weight: 700;
	margin-top: 15px;
	text-transform: uppercase;
}

.project-info label.group-title.inline-block {
	margin: 0 0 14px 0;
}

.form-design label {
	font-size: 1rem;
	color: #404040;
	font-weight: 500;
	font-family: "DM Sans", Verdana;
}

.form-design .form-control {
	background-color: #fff;
	border: 1px solid #cdcdcd;
	border-radius: 3px;
	padding: 8px 12px;
	height: 39px!important;
}

.form-design .card-body .col-lg-2:first-child .input-as-div:first-child {
	background: #f5f5f5;
	color: #333;
	font-weight: 700;
}

.form-design textarea.form-control {
	height: auto!important;
}

.form-design hr.breakline {
	border: .5px solid #cdcdcd;
	margin: 10px 0;
	display: block;
}

#frmMainPersonalData hr.breakline {
	display: none;
}

.form-design button.btn.btn-secondary i {
	font-size: .8rem;
}

table.form-table {
	border: 1px solid #ddd;
	margin: 10px 0 10px 0;
}

table.form-table td {
	padding: 4px 1rem;
	border-right: 1px solid #ddd!important;
}

.project-list-box {
	margin-top: 85px;
	min-height: 80vh;
	padding-bottom: 40px;
}

.form-table .utilicon-trash-alt {
	border: none;
	margin: auto;
	color: #d20000;
	background-color: #fff;
}

.partner-services .service-image {
	padding: 22px 10px;
	text-align: center;
	min-width: 150px;
	width: auto;
	font-size: 1.1rem;
	background-color: #dedede;
	cursor: pointer;
}

.partner-services .service-image:hover {
	background-color: #d4d1d1;
}

.partner-services .service-image .utilicon-check-circle {
	position: absolute;
	right: 8px;
	top: 8px;
	color: #fff;
	display: none;
}

.partner-services .service-image.activated .utilicon-check-circle {
	display: block;
}

.partner-services .service-image.activated {
	background-color: #717fc4;
}

.partner-services .service-image img {
	height: 45px;
}

.partner-services .service-name {
	font-size: 1rem;
}

.partner-services-list .service-image {
	background-color: #dedede;
	position: relative;
	padding: 16px 10px;
	width: 100px;
}

.partner-services-list .service-image:hover {
	background-color: #d4d1d1;
}

.partner-services-list .service-image.activated {
	background-color: #717fc4;
}

.partner-services-list .service-image .utilicon-check-circle {
	position: absolute;
	right: 5px;
	top: 5px;
	color: #fff;
	display: none;
	font-size: 1.1rem;
}

.partner-services-list .service-image.activated .utilicon-check-circle {
	display: block;
}

.partner-services-list .service-image img {
	height: 33px;
}

.form-table .form-group small {
	font-size: .75rem;
	font-weight: 700;
}

.form-design .form-box.actions {
	text-align: center;
}

.form-design .form-box button.btn {
	margin: 0 6px;
	padding: 5px 25px;
	text-transform: none;
	font-size: .9rem;
	width: auto;
	min-width: auto;
	line-height: normal;
	height: 37px;
	font-weight: 400;
	border: 1px solid #413f3f;
	background-color: #fff;
	color: #413f3f;
}

.form-design .form-box button.btn.btn-primary {
	background-color: #65B35C;
	border-color: #65B35C;
	border-radius: 3px;
	font-weight: 700;
	color: #fff;
	margin: 0 6px!important;
}

.form-design .form-box button.btn.btn-primary:hover {
	background-color: #3b9d31;
}

.user_preference {
	margin-top: 9px;
	position: relative;
	float: right!important;
	width: 60px;
	height: 60px;
	text-align: center;
	justify-content: center;
}

.user_preference .avatar {
	cursor: pointer;
}

.user_preference_dropdown {
	list-style: none;
	margin: 0;
	position: absolute;
	min-width: 153px;
	right: 0;
	top: 74px;
	background-color: #e0e5f5;
	padding: 10px;
	z-index: 6;
	border-radius: 2px;
	box-shadow: 0 0 5px #ddd;
	display: none;
}

.user_preference_dropdown li {
	font-size: .813em;
	text-align: left;
	color: #4b4a4a;
	padding: 4px 0;
	font-weight: 400;
}

.user_preference_dropdown li a {
	color: #4b4a4a;
	text-decoration: none;
	padding: 4px 0;
	display: block;
}

.project-list .view-more {
	background-color: #727A06;
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	color: #fff;
	border-radius: 0;
	text-align: center;
	font-size: 1rem;
	padding: 0 13px;
}

.project-list .view-more:hover {
	background-color: #B2BF00;
}

.project-list .view-more span {
	transform: rotate(270deg);
	transform-origin: 100% 100%;
	display: inline-block;
	position: absolute;
	left: 0;
	width: 100%;
	white-space: nowrap;
	text-indent: -29px;
}

.user-dropdown {
	display: inline-block;
}

.user-dropdown .dropdown-toggle {
	text-align: center;
	background-color: #307297;
	margin: 0 0 0 16px;
	padding: 17px 14px;
	color: #fff;
	font-size: 1.2rem;
	font-weight: 700;
	text-decoration: none;
}

.user-dropdown .dropdown-toggle:focus {
	color: #fff;
}

.user-dropdown .dropdown-toggle:after {
	font-size: 17px;
	color: #fff;
	opacity: .5;
}

.user-dropdown ul.dropdown-menu {
	background-color: #717fc4;
	border: none;
	right: 0;
	transform: translate(0,0)!important;
	margin: 0;
	padding: 6px 0;
	border-radius: 3px;
	position: absolute;
	left: auto!important;
	top: 58px!important;
}

.designation-alias {
	color: #fff;
	font-size: 11px;
	display: block;
	line-height: normal;
	white-space: nowrap;
}

.user-dropdown ul.dropdown-menu li {
	padding: 7px 10px;
	border-bottom: 1px solid #8c97cc;
	font-size: 12px;
}

.user-dropdown ul.dropdown-menu li:hover {
	background-color: #6976b7;
}

.user-dropdown ul.dropdown-menu li:last-child {
	border: none;
}

.user-dropdown ul.dropdown-menu li a {
	display: block;
	cursor: pointer;
	font-size: 12px;
}

.user-dropdown ul.dropdown-menu li a img {
	float: right;
	margin-top: 4px;
	width: 13px;
}

.list-project .mdm-box li {
	background-color: #CBE7F5;
	border-radius: 2px!important;
	padding: 10px 11px!important;
	text-align: center;
}

.mdm-box li .box_mh {
	background-color: #fff;
	border-radius: 8px;
	margin-top: 50px!important;
	padding: 0 8px;
}

.list-project .mdm-box li .box.box_mh img {
	height: 100px!important;
	margin-top: -39px!important;
}

.list-project ul.mdm-box li span.box h3 {
	text-transform: capitalize;
	margin: 1px 0 0 0;
	padding: 4px 0 2px 0;
}

#myList.list-project ul.mdm-box li span.box p {
	color: #7d7c7c!important;
	word-break: break-word;
	max-height: initial;
	min-height: 37px;
	padding: 0;
	color: #292929;
	line-height: normal;
	margin-bottom: 10px;
}

.btn-primary.btn {
	background-color: #65B35C;
	min-width: auto;
	padding: 6px 12px;
	border-radius: 3px;
	border: 1px solid #65B35C;
	text-transform: capitalize;
	margin: 15px 0 5px 0;
}

.btn span {
	font-size: 14px;
}

.btn-primary.btn:focus {
	background-color: #3b9d31;
	border-color: #3b9d31;
}

.btn-primary.btn:hover {
	background-color: #3b9d31;
	border-color: #3b9d31;
}

button.btn.btn-secondary {
	background-color: #fff;
	min-width: auto;
	padding: 6px 12px;
	border-radius: 3px;
	border: 1px solid #909090;
	text-transform: capitalize;
	margin: 15px 0 5px 10px;
	display: inline-block;
	color: #909090;
}

button.btn.btn-secondary:hover {
	background-color: #fff;
	color: #4e4c4c;
}

button.btn.btn-secondary:focus {
	color: #4e4c4c;
	background-color: #fff!important;
}

.inline-block {
	display: inline-block!important;
}

.datatable-header {
	text-align: right;
	padding-right: 0;
}

.datatable-header .btn {
	margin-right: 10px;
	vertical-align: top;
	text-transform: capitalize;
}

.datatable-header select {
	margin: 0 0 0 8px!important;
}

.table tbody tr td {
	padding: 4px 15px!important;
	border: 1px solid #ddd;
	font-size: .8rem;
	font-family: "DM Sans", Verdana;
}

.table tbody tr td strong {
	font-family: "DM Sans", Verdana;
}

.table tbody tr th strong {
	font-family: "DM Sans", Verdana;
}

.table tbody tr:first-child td {
	border: 1px solid #e4e4e4;
}

.delete-btn {
	background-color: #d20000;
	padding: 6px 8px;
	border: none;
	border-radius: 3px;
	cursor: pointer;
}

.view-btn {
	background-color: #307297;
	padding: 6px 8px;
	border: none;
	border-radius: 3px;
	cursor: pointer;
}

.edit-btn {
	background-color: #277E6E;
	padding: 6px 8px;
	border: none;
	border-radius: 3px;
	cursor: pointer;
}

.modal .close img {
	width: 10px;
}

.modal .close {
	background-color: #d20000;
	opacity: 1;
	margin: -12px -13px;
	padding: 5px 8px;
}

.modal .close i {
	color: #fff;
	font-size: .8rem;
}

.dateIcon i {
	position: absolute;
	right: 6px;
	bottom: 9px;
	font-size: 1rem;
}

.dateIcon {
	position: relative;
}

a#forgotPwd {
	border: none;
	background-color: transparent;
	color: #0078d1;
	margin: 14px 0 0 0;
	font-size: 12px;
	text-align: center;
	display: inline-block;
}

a#forgotPwd:hover {
	text-decoration: none;
}

a#forgotPwd:focus {
	background-color: transparent!important;
}

.btn svg {
	height: 15px;
	width: auto;
	margin: 5px 7px 0 0;
}

.btn svg path {
	fill: #fff!important;
}

.farmer-profile-div .nav-pills {
	background-color: #f5f5f5;
	border-radius: 0;
	margin: 20px 0;
	display: block;
	white-space: nowrap;
}

.nav-pills .progress-circle {
	display: inline-block;
	width: 30px;
	height: 30px;
	border: 1px solid #767676;
	border-radius: 100%;
	background-color: #fff;
	line-height: 30px;
	position: relative;
	z-index: 1;
}

.tab-pane {
	position: relative;
}

.farmer-profile-div .nav-pills li {
	display: inline-block;
	border: 0;
}

.farmer-profile-div .nav-pills li.visited .utilicon:before {
	content: "\E1B4";
}

ul.fixed-tabs li a.active:before {
	width: 0;
	height: 0;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-bottom: 9px solid #fff;
	content: "";
	position: absolute;
	bottom: -3px;
	left: 0;
	right: 0;
	margin: 0 auto;
}

.farmer-profile-div .nav-pills li a {
	padding: 6px 40px;
	border-radius: 0;
	margin-right: 5px;
}

ul.sub-tab-fixed li a.active {
	border-bottom: 2px solid #727A06;
}

ul.sub-tab-fixed li a.active span {
	color: #727A06!important;
	top: 3px;
}

ul.nav-pills .nav-link {
	background-color: transparent;
	color: #2a2a2a;
	text-align: center;
	position: relative;
	text-transform: capitalize;
}

.farmer-tab-content {
	border: none;
}

.nav-pills .nav-link.active {
	background-color: transparent;
	color: #2a2a2a;
}

.nav-pills,.nav-tabs {
	position: relative;
	justify-content: space-between;
}

ul#farmer-tabs {
	justify-content: inherit;
	overflow-x: auto;
}

.nav-pills:before {
	content: "";
	background-color: #bcbbbb;
	height: 1px;
	width: 100%;
	top: 32%;
	position: absolute;
	display: none;
}

.nav-pills .nav-link:hover {
	background-color: transparent;
}

.nav-pills .nav-link.active .progress-circle .utilicon:before {
	content: "\E1C9";
	color: #fff;
}

.nav-pills .nav-link.visited .progress-circle .utilicon:before {
	content: "\E1B4";
	color: #767676;
}

.nav-pills .nav-link.visited {
	border-color: #767676;
}

.nav-pills .nav-link.visited.active .progress-circle {
	background-color: #fff;
}

.nav-pills .nav-link.active .progress-circle {
	background-color: #727A06;
	border-color: #727A06;
}

.nav-pills .nav-link .progress-circle .utilicon:before {
	content: "/";
	color: #fff;
}

.farmer-iamge {
	display: inline-block;
	margin: 0 30px 0 0;
}

.farmer-iamge:after {
	content: "\E023";
	font-family: utilicon;
	margin: 0 30px;
	font-size: 20px;
	position: relative;
	color: #b7b8b7;
	top: 0;
	display: none;
}

.farmer-qrcode {
	display: inline-block;
}

.file-upload .input-group-btn {
	position: absolute;
	left: 0;
	top: 0;
}

.file-upload .input-group-btn .btn-file {
	margin: 0;
	padding: 8px 19px;
}

.farmer-tab-content .tab-pane.fade:not(.show) {
	opacity: 0;
	display: none;
}

.farmer-tab-content .tab-pane.fade.active {
	opacity: 1;
	display: block;
}

.gender-selection .gender-icon svg {
	position: absolute;
	left: 0;
	opacity: .18;
}

.gender-selection input[type="radio"] {
	opacity: 0;
	position: relative;
	z-index: 1;
	width: 40px;
	height: 40px;
	cursor: pointer;
}

.gender-icon {
	position: relative;
	display: inline-block;
	font-size: .7rem;
	text-align: center;
	margin: .2rem 1.5rem .7rem 0;
}

.gender-icon input[type="radio"]:checked~svg {
	opacity: 1;
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
	padding-bottom: 3px;
	border-radius: 3px;
	background-color: #d8d8d8;
}

.gender-icon input[type="radio"]:checked~i {
	opacity: 1;
}

.gender-icon input[type="radio"]~i {
	opacity: 0;
	background-color: #d8d8d8;
	width: 16px;
	height: 16px;
	display: inline-block;
	line-height: 17px;
	font-size: 8px;
	border-radius: 24px;
	z-index: 9;
	position: absolute;
	top: -3px;
	left: -7px;
}

.modal-body .form-design {
	border: none;
	margin: 0;
}

.file-upload input[type="file"] {
	margin: 5px 0 0 7px;
}

.parent-mapping {
	color: #727A06;
	font-weight: 500;
}

.child-mapping {
	color: #292929;
	padding-left: 30px;
	position: relative;
	font-size: 13px;
}

.child-mapping:before {
	width: 1px;
	height: 36px;
	background-color: #979797;
	content: "";
	position: absolute;
	left: 0;
	bottom: 7px;
}

.child-mapping:after {
	width: 21px;
	height: 1px;
	background-color: #979797;
	content: "";
	position: absolute;
	left: 0;
	top: 50%;
}

.partner-services table tr:nth-child(2) .child-mapping:before {
	height: 26px;
	bottom: 0;
	top: auto;
}

.partner-services table td .parent-mapping .utilicon {
	top: 4px;
	font-size: 17px;
	left: -6px;
	z-index: 9;
}

.partner-services table tr td,th {
	padding: 4px 15px!important;
}

.partner-services table tr.comp-open td .parent-mapping .utilicon:before {
	content: "-";
	font-size: 43px;
	line-height: 0;
}

.partner-services table tr.comp-closed td .parent-mapping .utilicon:before {
	content: "\E1CC";
}

.partner-services table tr.noComponents .parent-mapping .utilicon {
	visibility: hidden;
	opacity: 0;
}

.project-info-box .table {
	border-color: #e4e4e4;
}

.project-info-box .table td,th {
	border: 1px solid #ccc!important;
}

.project-info-box {
	margin: 10px 0 0 0;
}

.project-info-box .table td.project-info-label {
	font-weight: 500;
	background-color: #f7f7f7;
	width: 200px;
}

.stage-info .badge {
	font-size: .75rem;
	padding: 4px 10px;
	height: auto;
}

.stage-info h4.card-title {
	color: #404040;
	text-transform: none;
	display: block;
	margin: 8px 0 15px 0;
}

.snapshot-btn-align {
	text-align: right;
}

.select-snap-v {
	margin-bottom: 18px;
}

.table-wrapper table tr td:empty {
	display: none;
}

.datasheet-table table tr td:empty {
	display: table-cell;
}

.farmer-gray-table tr td:empty {
	display: table-cell!important;
}

.datasheet-table table tr td {
	white-space: inherit!important;
}

.comp-open {
	cursor: pointer;
}

.comp-closed {
	cursor: pointer;
}

.noComponents {
	cursor: auto!important;
}

.snapshot-status .badge {
	font-size: 12px;
	height: auto;
	padding: 5px 12px;
	border-radius: 50px;
}

div.make-revision-btn .btn-primary {
	margin: 0 8px 10px 0;
}

div.make-revision-btn .btn-secondary {
	margin: 0 8px 10px 0;
}

.snapshot-status .badge {
	height: auto;
	padding: 5px 12px;
	border-radius: 50px;
	margin-right: 5px;
}

.status-box span {
	font-size: 15px;
}

.status-box {
	font-size: 11px!important;
}

.snapshot-status p {
	display: block!important;
}

.snapshot-status h4 {
	margin: 13px 0 16px 0;
	display: block;
}

.snapshot-status {
	padding-bottom: 20px;
}

.multiselect-dropdown .dropdown-btn,input.form-control,select.form-control {
	height: 32px!important;
}

.table tr th img .sorting-arrow {
	margin-top: -2px;
}

.table tr td a {
	color: #4f598b;
}

.scroll-to-top .btn {
	float: right;
}

.fixed-tabs:before {
	display: none;
}

ul.fixed-tabs {
	position: fixed;
	top: 94px;
	left: 64px;
	right: 0;
	width: calc(100% - 64px);
	margin: 0!important;
	z-index: 99;
}

ul.fixed-tabs li {
	width: 20%;
}

ul.fixed-tabs li a {
	padding: 12px 20px;
	border-bottom: 3px solid transparent;
	border-radius: 0!important;
}

ul.fixed-tabs li a:hover {
	background-color: #727A06!important;
	color: #fff;
}

ul.fixed-tabs li a .utilicon {
	margin-left: 5px;
	position: relative;
	top: 1px;
	display: none;
}

ul.fixed-tabs li a.active .utilicon {
	display: inline-block;
}

ul.fixed-tabs li a.active .utilicon:before {
	color: #fff;
}

ul.fixed-tabs li a.active {
	border-top: 2px solid #727A06;
	color: #fff!important;
	background-color: #727A06!important;
	border-left: 1px solid #727A06;
	border-right: 1px solid #727A06;
}

ul.sub-tab-fixed {
	position: fixed;
	left: 64px;
	z-index: 99;
	top: 116px;
	width: calc(100% - 64px);
	background-color: #fff!important;
	overflow-x: auto;
}

ul.sub-tab-fixed li a .utilicon-check {
	opacity: 0;
}

ul.sub-tab-fixed li a.active .utilicon-check {
	opacity: 1;
}

.sub-tab-fixed li:last-child a {
	border: none;
}

div.profiling-screen {
	margin-top: 206px!important;
}

.farmer-tab-content .border-middle {
	display: none;
}

ul.sub-tab-fixed li.nav-item a.active {
	border-bottom: none;
	background-color: #CBD938;
	margin-bottom: 10px;
}

ul.sub-tab-fixed li.nav-item a.active:before {
	width: 0;
	height: 0;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-top: 6px solid #CBD938;
	content: "";
	position: absolute;
	bottom: -6px;
	left: 0;
	right: 0;
	margin: 0 auto;
}

ul.sub-tab-fixed li.nav-item a.active span {
	color: #404040!important;
}

ul.sub-tab-fixed li a {
	cursor: pointer;
}

ul.sub-tab-fixed li a:hover {
	background-color: #CBD938!important;
}

.form-design .fixed-actions {
	text-align: center;
	position: fixed;
	width: calc(100% - 82px);
	bottom: 32px;
	background-color: #fff;
	z-index: 99;
	left: 82px;
	right: 0;
	margin: 0;
}

.col50 {
	margin-right: 2%;
	display: flex!important;
}

.form-design .farmer-tab-content .form-group label {
	width: 100%;
}

.form-design .farmer-tab-content .form-group {
	margin-bottom: 0;
}

.form-sub-section {
	padding: 6px 20px;
	border: 1px solid #f5f5f5;
	background-color: #f5f5f5;
	border-radius: 4px;
	margin-top: 15px;
}

.form-design .alert {
	margin: 15px 20px;
}

.form-sub-section h3 {
	color: #1d2239;
	font-size: 1.2rem!important;
	margin: 9px 0 0 0;
	text-transform: capitalize;
	border-bottom: 1px solid #ddd;
	margin-bottom: 7px;
}

.project-info .card-header {
	font-weight: 700;
	font-size: 1rem;
	padding: 7px 16px;
	text-transform: capitalize;
}

.project-info .card-title strong {
	font-weight: 700;
}

.project-info .card-body {
	padding: 7px 16px 0 16px;
	height: 172px;
	display: grid;
	align-items: center;
}

.project-info .card-body select {
	margin-bottom: 10px;
	width: 100%;
	height: 32px!important;
}

.project-info .card {
	margin-bottom: 20px;
}

.email-btn {
	background-color: #fdb128;
}

.email-btn svg {
	height: 15px;
}

.print-btn {
	background-color: #76e2f7;
}

.print-btn svg {
	height: 15px;
}

table.table tr th {
	padding: 9px 15px!important;
	font-size: .9rem;
	font-weight: 500;
	border-color: #e4e4e4!important;
}

table.table {
	border-bottom: none;
}

.add-farmer-form .container-fluid {
	padding: 0;
}

.add-farmer-form .form-design {
	padding-bottom: 25px;
}

.designation-alias {
	font-size: 11px;
	color: #fff;
	display: block;
}

.border-middle {
	background-color: #bcbbbb;
	height: 1px;
	width: 100%;
	top: 66px;
	position: absolute;
	z-index: 1;
}

.login-tab-header {
	display: none;
	padding: 10px 20px;
	text-align: center;
	z-index: 99;
	background-color: #fff;
	position: relative;
}

.login-tab-header img {
	width: 180px;
}

.footer-links {
	text-align: center;
	padding: 3px 0;
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	background-color: #f5f7ff;
	z-index: 99;
}

.footer-links p {
	display: inline-block;
	margin: 0 8px 0 0;
	font-size: 10px;
}

.footer-links a {
	border-left: 1px solid #c1bfbf;
	padding: 0 8px;
	font-size: 10px;
}

.msg-wizard {
	position: fixed;
	right: 15px;
	bottom: 24px;
	z-index: 999;
}

.msg-wizard-action {
	background-color: #307297;
	width: 45px;
	height: 45px;
	border-radius: 100%;
	text-align: center;
	border: 1px solid #e4e7f9;
	box-shadow: 0 0 15px #00000045;
	cursor: pointer;
	float: right;
}

.msg-wizard-action:hover {
	background-color: #0B3C56;
}

.msg-wizard-action i {
	color: #fff;
	font-size: 21px;
	margin-top: 11px;
	display: block;
}

.msg-wizard .form-wizard {
	background-color: #fff;
	padding: 15px 16px;
	width: 300px;
	border-radius: 5px;
	box-shadow: 0 0 20px #00000042;
	margin: 0 0 10px 0;
}

.msg-wizard .form-wizard .btn {
	margin-top: 0;
	padding: 4px 9px;
	font-size: 12px;
}

.msg-wizard .form-wizard h3 {
	font-size: 1.1rem!important;
	margin: 0 0 8px 0;
	line-height: normal;
}

.form-wizard .farmer-name {
	float: left;
	width: 100%;
	font-size: 12px;
	margin-bottom: 6px;
}

.form-wizard .farmer-iamge {
	width: 21%;
	margin-right: 0;
	float: left;
}

.form-wizard textarea.form-control {
	font-size: 12px;
	padding: 3px 8px;
}

.form-wizard form .form-group {
	margin-bottom: 0;
}

.form-wizard .farmer-name span {
	float: left;
	width: 50%;
	line-height: normal;
}

.form-wizard .farmer-iamge #img-upload {
	height: auto;
	max-width: 100%;
}

.scroll-to-top {
	bottom: 19px!important;
	right: 84px!important;
	position: fixed;
	z-index: 999;
}

.login-info button.btn.btn-secondary {
	margin-top: 6px;
}

.padB0 {
	padding-bottom: 0!important;
}

.form-design .accordion {
	border: none;
}

.form-design .collapsing {
	width: 100%;
}

.form-design .accordion .collapsed .utilicon-chevron-down {
	transform: rotate(180deg);
}

.form-design .accordion .utilicon-chevron-down {
	margin: 0 10px 0 0;
	font-size: 15px;
	text-align: center;
	background-color: #89C7E8;
	border-radius: 4px;
	height: 27px;
	line-height: 27px;
	float: left;
	width: 30px;
	cursor: pointer;
	color: #000;
}

.datatable-header button.btn.btn-secondary {
	margin-top: 0;
}

.questionnaire-tabs {
	border-top: 1px solid #ddd;
	padding-top: 17px;
}

.questionnaire-tabs .badge {
	font-size: 11px;
	height: auto;
	padding: 5px 12px;
	border-radius: 50px;
}

.questionnaire-tabs .nav-tabs .nav-item .nav-link.active {
	background: #61765b;
	border-color: #61765b;
}

.questionnaire-tabs .nav-tabs .nav-item .nav-link {
	border-color: #d2d2d2;
	color: #656565;
}

.questionnaire-tabs .nav-tabs.nav-tabs-secondary .nav-item .nav-link:hover {
	color: #fff;
	border-color: #61765b;
}

.questionnaire-tabs .nav-tabs {
	border-bottom: 2px solid #d2d2d2;
}

.questionnaire-tabs .card-header {
	padding: 9px 13px 9px 19px;
}

.questionnaire-tabs .nav-tabs .nav-item .nav-link.active:hover {
	color: #fff;
}

.questionnaire-tabs .card-header h4 {
	display: inline-block;
}

.questionnaire-tabs .card-header .btn-outline-danger {
	background-color: #d20000;
	color: #fff;
	width: 22px;
	height: 22px;
	border: none;
}

.questionnaire-tabs label {
	width: 85%;
	float: left;
}

.questionnaire-tabs .disabled {
	background-color: transparent;
}

.questionnaire-tabs .form-group {
	height: 70px;
}

.padB0 {
	padding-bottom: 0;
}

.dashboard-box {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	background-color: #aed1aa;
	border-radius: 3px;
	border: 1px solid #9dd497;
}

.form-design .farmer-tab-content .form-group {
	min-height: 90px;
}

.btn:disabled {
	cursor: not-allowed!important;
}

div.bs-datepicker-body table.days span {
	width: 22px;
	height: 22px;
	line-height: 22px;
}

div.bs-datepicker-head {
	height: 34px;
	padding: 2px 7px;
	font-size: 12px;
	width: 100%;
	text-align: center;
}

.bs-datepicker-body table th {
	padding: 4px 6px!important;
	border: none;
	border-right: none!important;
}

.bs-datepicker-body table th:last-child {
	border-right: 1px solid #ccc!important;
}

div.bs-datepicker-container {
	padding: 0;
}

div.bs-datepicker-body {
	min-height: 192px;
}

td.bs-datepicker-body table.months td {
	height: auto;
}

span.bs-datepicker-body table.months td span {
	padding: 8px 2px;
}

.bs-datepicker-head,button.previous {
	float: left;
}

.bs-datepicker-head,button.next {
	float: right;
}

ul.reports-list {
	list-style: none;
	margin: 10px 0 0 0;
	padding: 0;
	display: flex;
	width: 100%;
	flex-wrap: wrap;
}

ul.reports-list li {
	background-color: #717fc4;
	margin-right: 10px;
	padding: 10px;
	border-radius: 4px;
	text-align: center;
	width: 160px;
	margin-top: 10px;
	cursor: pointer;
	display: table;
	height: 82px;
	font-size: 12.5px;
	position: relative;
	color: #fff;
}

ul.reports-list li span {
	display: table-cell;
	vertical-align: middle;
	line-height: 20px;
}

ul.reports-list li:hover {
	background-color: #727A06;
}

ul.reports-list li img {
	position: absolute;
	left: 86px;
	top: 14px;
	right: 0;
	width: 50px;
	margin: 0 auto;
	opacity: .1;
}

ul.reports-list li .utilicon-check-circle {
	position: absolute;
	top: 6px;
	left: 5px;
	font-size: 15px;
	opacity: 0;
}

.report-checkall {
	margin: 16px 0 0 8px;
}

.nav-tabs.nav-tabs-secondary {
	border: none;
	background-color: #f5f5f5;
}

.nav-tabs.nav-tabs-secondary .nav-item .nav-link {
	background-color: transparent;
	color: #2a2a2a;
	text-align: center;
	position: relative;
	text-transform: capitalize;
	border: none;
	border-radius: 0;
	padding: 6px 40px;
	margin-right: 0;
}

.nav-tabs.nav-tabs-secondary .nav-item .nav-link.active {
	background-color: #727A06;
}

div.view-profile .tab-content {
	margin-top: 0;
	padding: 0;
}

.nav-tabs.nav-tabs-secondary .nav-item .nav-link:hover {
	border-color: #727A06;
	background-color: #727A06!important;
	color: #fff;
}

.view-profile .card-header h4 {
	margin: 0;
	text-transform: capitalize;
	font-size: 1.3em!important;
	color: #000;
}

.view-profile .card-header {
	padding: 6px 15px;
}

.table td span {
	white-space: normal;
}

.nav-tabs.nav-tabs-secondary .nav-item {
	margin-right: 0;
}

.form-check .form-check-input.disabled+.form-check-label:before,.form-check .form-check-input:disabled+.form-check-label:before {
	border-color: #8c8c8c!important;
}

.form-check input[type="checkbox"].form-check-input.active+.child-table-check:before,.form-check input[type="checkbox"].form-check-input.checked+.child-table-check:before,.form-check input[type="checkbox"].form-check-input:checked+.child-table-check:before {
	background-color: #717fc4!important;
	border-color: #717fc4!important;
}

.child-table-input {
	margin-left: 20px;
}

span.hline {
	height: 1px;
	width: 35px;
	display: inline-block;
	background-color: #d6d5d5;
	position: absolute;
	top: 14px;
	left: 0;
}

span.hline:before {
	content: "";
	background-color: #d6d5d5;
	height: 1px;
	width: 35px;
	display: inline-block;
	transform: rotate(90deg);
	left: -17px;
	position: absolute;
	top: 0;
	bottom: 0;
}

.oc-all {
	border: none;
	padding: 1px 6px 0 0;
	background-color: #f7f7f7;
	float: left;
	font-size: 17px;
	color: #727A06;
	margin-left: -4px;
}

.oc-all:focus {
	outline: 0;
	box-shadow: none;
}

.accordion label.group-title {
	font-size: 1.1rem;
	margin: 0;
}

.project-info {
	margin-top: 10px;
}

.accordion .table tbody tr td {
	padding: 4px 15px!important;
	font-size: .8rem;
}

.print-projectinfo {
	margin-top: 2px;
}

.print-projectinfo .print-btn {
	background-color: #fff;
	padding: 5px 8px 4.5px 8px;
	font-weight: 700;
	font-size: .8rem;
}

.print-projectinfo .print-btn:hover {
	background-color: #d3eef3;
}

.print-projectinfo .print-btn svg {
	margin: -1px 0 0 6px;
}

.print-projectinfo .print-btn span {
	font-weight: 700;
	font-family: DINPro!important;
}

.print-btn.export-pdf {
	margin: 0 0 0 7px;
}

.mapping-for-print .service-image img {
	display: none;
}

.mapping-for-print .service-image .service-name {
	color: #000;
	margin-top: 0;
}

.mapping-for-print .service-image {
	background-color: #f3f1f1;
	border: 1px solid #cecece;
	border-radius: 1px;
	width: auto;
	margin-right: 5px;
	padding: 9px 11px;
	cursor: inherit;
}

.mapping-for-print .service-image:hover {
	background-color: #fff;
}

.table.table-one-line td.wrap-text {
	white-space: normal;
}

table.table tr td {
	white-space: normal!important;
}

table.table tr td:last-child {
	white-space: nowrap!important;
}

table.table-project-info tr td:last-child {
	white-space: normal!important;
}

.scroll-to-top {
	position: fixed;
	bottom: 15px;
	right: 15px;
	opacity: 0;
	transition: all 0.2s ease-in-out;
}

.show-scrollTop {
	opacity: 1;
	transition: all 0.2s ease-in-out;
}

.cursorPointer {
	cursor: pointer;
}

.cursorPointer:hover {
	text-decoration: underline;
}

button.cancel-focus {
	box-shadow: inset 0 0 28px #293225;
}

.profile-completed {
	display: inline-block;
	height: 28px;
	font-size: 10px;
	margin-right: 8px;
	padding: 3px 1px;
	width: 28px;
	border-radius: 5px;
	font-weight: 700;
	text-align: center;
	line-height: 23px;
	margin-bottom: 3px;
}

.profile-not-completed {
	display: inline-block;
	height: 28px;
	font-size: 10px;
	margin-right: 8px;
	padding: 3px 2px;
	width: 28px;
	border-radius: 5px;
	font-weight: 700;
	text-align: center;
	line-height: 23px;
	margin-bottom: 3px;
}

.profile-completed-lessthan-50 {
	background-color: #D34252;
}

.profile-completed-greaterthan-50 {
	background-color: #F08B1F;
}

.profile-completed-100-percent {
	background-color: #B2BF00;
}

.profile-pending-text i {
	line-height: normal;
	white-space: inherit!important;
	color: #307297;
	font-weight: 500;
	display: inline-block;
	font-size: 20px;
	top: 6px;
}

.profile-pending-text i.utilicon-check-circle {
	color: #61765b;
}

.add-farmer-form label {
	font-size: .85rem;
	margin-bottom: .2rem;
}

.add-farmer-form .form-group {
	margin: 0 0 10px 0;
}

.reset-username {
	display: flex;
	justify-content: space-between;
}

.password-strength-div {
	display: block;
	margin-bottom: 24px;
}

.datasheet-info {
	background-color: #CBE7F5;
	box-shadow: 0 1px 4px #cacaca;
	border-radius: 3px;
	margin: 15px 0;
	height: calc(100% - 30px);
}

.datasheet-farmer-img {
	width: 25%;
	display: inline-block;
	margin: 13px 17px;
}

.datasheet-farmer-img img {
	max-width: 100%;
}

.datasheet-detail {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #fff;
}

.name-age span {
	display: block;
}

.name-age {
	font-size: 17px;
	font-weight: 400;
	width: 80%;
}

.datasheet-info table {
	margin-bottom: 0;
	background-color: #CBE7F5;
}

.datasheet-info table tbody tr td:nth-child(2) {
	border-right: 1px solid #ddd;
}

.datasheet-table {
	margin: 15px 0;
}

.errorDiv {
	color: #ef001d;
	font-size: 12.5px;
	line-height: initial;
}

.datasheet-table {
	width: 100%;
	margin-top: 15px;
}

.table-wrapper.datasheet-table tr td:last-child {
	white-space: normal!important;
}

.view-profile-data ul {
	margin: 0;
	padding: 0;
}

.view-profile-data ul li {
	list-style: none;
	display: block;
	border-bottom: 1px solid #dadde9;
	border-left: 1px solid #dadde9;
	border-right: 1px solid #e4e4e4;
	font-size: .78rem;
	align-items: center;
	float: left;
	width: 100%;
	display: flex;
	justify-content: space-between;
	background-color: #CBE7F5;
}

.view-profile-data h3 {
	background-color: #f7f7f7;
	font-size: 0.9rem!important;
	color: #292929;
	padding: 2px 11px;
	border-radius: 5px 5px 0 0;
	box-shadow: 0 1px 3px #d6d3d3;
	margin-bottom: 0;
	font-family: "DM Sans", Verdana;
}

.view-profile-data ul li .question-area {
	width: 40%;
	padding: 7px 10px;
	font-weight: 500;
	background-color: #CBE7F5;
	display: inline-block;
}

.view-profile-data ul li .nested-ul {
	background-color: #dadde9;
	display: block;
	margin: 0 3% 20px 3%;
	border-radius: 3px;
	padding-bottom: 15px;
	position: relative;
	width: calc(100% - 6%);
}

.view-profile-data ul li ul.nested-ul span.asnwer-area {
	float: none;
	margin-left: 2px;
}

.view-profile-data ul li .asnwer-area {
	width: 58%;
	float: right;
	padding: 7px 7px 7px 20px;
	background-color: #fff;
}

.view-profile-data ul li .nested-ul h3 {
	background-color: transparent;
	box-shadow: none;
	padding: 6px 20px 2px 20px;
}

.view-profile-data ul li .nested-ul li {
	background-color: transparent;
	margin: 0 0 0 20px;
	width: calc(100% - 20px);
	justify-content: unset;
}

.view-profile-data ul li .nested-ul li span {
	background-color: #fff;
	display: inline-block;
	width: 25%;
	margin: 0;
	padding: 7px 10px;
}

.view-profile ul.fixed-tabs li a {
	display: block;
	text-align: center;
	position: relative;
}

.view-profile ul.fixed-tabs li a:hover {
	text-decoration: none;
}

.error-title {
	color: #717fc4;
	font-size: 7.2rem!important;
	font-weight: 700;
	margin: -40px 0 0 0;
}

.error-template p {
	color: #1d2239;
	font-size: 1.2rem;
	line-height: 35px;
}

.error-template p.small-text {
	font-size: 1rem;
}

.error-template p.small-text a {
	color: #5e5e5e;
	font-weight: 500;
	border-bottom: 2px solid #5e5e5e;
	text-decoration: none!important;
}

.error-msg .error-actions button {
	margin: 20px 0 0 0;
}

.error-template {
	text-align: center;
	margin-top: 130px;
}

.error-template svg {
	width: 350px;
}

.prev-btn {
	position: fixed;
	left: 63px;
	z-index: 9;
	height: 36px;
	border: none;
	padding: 0 9px;
	background-color: #eaeaea;
	box-shadow: 3px 1px 10px #656464;
}

.next-btn {
	position: fixed;
	right: 0;
	z-index: 9;
	height: 36px;
	border: none;
	padding: 0 9px;
	background-color: #eaeaea;
	box-shadow: 3px 1px 10px #656464;
}

.prev-btn:focus {
	outline: 0;
}

.next-btn:focus {
	outline: 0;
}

.profile-pending-text {
	width: 15px;
	display: inline-block;
	cursor: pointer;
}

.profile-pending-text img {
	max-width: 100%;
}

div.profiling-screen.tab_content {
	margin-top: 170px!important;
}

.quick_note {
	text-align: left;
	font-size: .95em;
	color: #000;
}

.login-p2froots-img-small {
	display: none;
}

@keyframes svg-animate1 {
	0% {
		transform: translateY(-30px);
	}

	50% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(-30px);
	}
}

.svg-animate1 {
	animation: svg-animate1 3s linear infinite;
}

@keyframes svg-animate2 {
	0% {
		color: #98a3de;
		transform: translateX(0);
	}

	30% {
		color: #7b84b6;
		transform: translateX(-15px);
	}

	70% {
		color: #717fc4;
		transform: translateX(15px);
	}

	100% {
		color: #6c749e;
		transform: translateX(0);
	}
}

.error-title {
	animation: svg-animate2 18s linear infinite;
}

.crop-names div {
	max-width: inherit;
}

.crop-names .field-name,.field-value div {
	margin: 0 5px 5px 0;
	float: left;
	width: 450px;
}

.issue-type {
	border: 1px solid #ddd;
	padding: 0 10px;
	border-radius: 4px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 36px;
}

.issue-type .form-check:first-child {
	border-right: 1px solid #ddd;
	margin-right: 17px;
}

.issue-type .form-check {
	width: 50%;
	margin: 0;
	height: 100%;
	display: flex;
	align-items: center;
}

.issue-type .form-check input.form-check-input {
	position: absolute;
}

.issue-type .form-check .form-check-label {
	margin: 0;
}

.select-frequency {
	background-color: #CBE7F5;
	border-radius: 2px;
}

.select-frequency button.active {
	background-color: #61765b;
	color: #fff;
}

.select-frequency button.active:hover {
	background-color: #61765b;
	color: #fff;
}

.select-frequency button.active i {
	display: inline-block;
	position: relative;
	top: 3px;
	font-size: 13px;
}

.select-frequency button {
	background-color: transparent;
	border: none;
	width: 33.33%;
	float: left;
	color: #a09c9c;
	padding: 7px 0;
	border-right: 1px solid #d5d7e4;
}

.select-frequency button:hover {
	background-color: #dcdfec;
}

.select-frequency button:last-child {
	border: none;
}

.select-frequency button i {
	display: none;
}

.sever-errors {
	position: relative;
	display: block;
}

.sever-errors .error {
	position: inherit;
}

.select-chart-wizard {
	left: 81px;
	right: auto;
}

.select-chart-wizard .msg-wizard-action {
	float: left;
}

.select-chart-wizard .dropdown-list ul {
	list-style: none;
	padding: 0;
	margin: 0;
	max-height: inherit!important;
}

.select-chart-wizard .form-wizard {
	height: 400px;
	overflow: auto;
	width: 400px;
	padding: 0;
}

.select-chart-wizard .form-check .form-check-label {
	font-size: .85rem;
}

.select-chart-wizard .dropdown-list[hidden] {
	display: block!important;
}

.select-chart-wizard .dropdown-btn {
	display: none!important;
}

.select-chart-wizard div.dropdown-list {
	margin: 0;
	box-shadow: none;
	border: none;
}

.error-msg {
	float: left;
	width: 70%;
	text-align: left;
	margin-top: 100px;
}

.error-img {
	float: right;
	width: 30%;
}

.error-img img {
	max-width: 100%;
}

.ngx-pagination {
	padding: 0;
}

.ecosystem-location {
	width: 100%;
	height: 520px;
	display: block;
}

.ecosystem-location a {
	font-family: "DM Sans"!important;
}

.ecosystem-location a strong {
	font-family: "DM Sans"!important;
}

.sidebar-open .menu-opened {
	display: none;
}

.sidebar-open .menu-closed {
	display: block;
	margin: 0 auto;
}

.menu-closed {
	display: none;
}

ul.fixed-tabs li:empty {
	display: none;
}

.sidebar-open ul.navbar-nav li.nav-item.tab-enabled {
	width: 50%!important;
	float: left;
}

.sidebar-open #sidebar .navbar-collapse .nav-item .nav-link {
	width: auto!important;
}

.sidebar-open #sidebar .navbar-collapse .nav-item .nav-icon {
	margin: 0 auto;
}

.sidebar-open ul.navbar-nav li.nav-item.tab-enabled:last-child {
	bottom: 0!important;
}

.sidebar-open ul.navbar-nav li.nav-item.tab-enabled .nav-text {
	display: none!important;
}

ul.navbar-nav li.nav-item.tab-enabled {
	position: absolute;
	width: 100%!important;
	display: block;
	bottom: 0;
	background-color: #897975!important;
}

ul.navbar-nav li.nav-item.tab-enabled a {
	width: 50%;
}

ul.navbar-nav li.nav-item.tab-enabled .nav-text .nav-text-overflow {
	color: #fff!important;
}

ul.navbar-nav li.nav-item.tab-enabled:last-child {
	right: 0;
}

ul.navbar-nav li.nav-item.tab-enabled:last-child {
	bottom: 72px;
}

button.btn.print-btn.view-btn.export-pdf {
	opacity: .8;
	color: #727A06;
}

button.btn.print-btn.view-btn.export-pdf:active,button.btn.print-btn.view-btn.export-pdf:focus {
	background-color: #B2BF00!important;
	border-color: #B2BF00!important;
	box-shadow: none!important;
	color: #fff!important;
}

.projectEstimatedYield {
	display: inline-block;
	width: 35%;
}

#farmerTableAll thead tr th img {
	margin-left: 7px;
}

.header button {
	background: #004281;
	color: #fff;
	width: 56px!important;
	height: 46px;
	left: 0;
	overflow: hidden;
	z-index: 1034;
	border: 0;
	position: absolute;
	outline: 0;
}

.header .logo {
	display: inline;
	margin-left: 70px;
}

.header .logo.mobile-view img {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	width: 60px;
	padding: 5px 10px 10px 7px;
}

.header .logo img {
	width: 140px;
	padding: 0 5px;
}

.header .deeproots-logo {
	display: inline-block;
	margin-left: 72px;
	position: relative;
	z-index: 1;
	padding-top: 7px;
}

.firmenich-header-right {
	position: relative;
	z-index: 1;
	float: right;
	padding: 15px 20px;
}

.firmenich-logo-center {
	position: absolute;
	text-align: center;
	top: 0;
	width: 100%;
	padding: 6px 0;
}

.header .deeproots-logo a {
	cursor: pointer!important;
	display: block;
	padding: 4px 5px;
}

.header .deeproots-logo img {
	height: 38px;
}

.header .logout-btn i {
	font-size: 20px;
	color: #fff;
}

#sidebar .navbar-collapse .nav-item {
	width: 4.6em;
}

#sidebar .navbar-collapse .nav-item {
	width: auto;
	cursor: pointer;
}

#sidebar .navbar-collapse .nav-item .nav-link {
	border-top: 1px solid #d1d5e8;
	padding: 6px 0;
}

#sidebar .navbar-collapse .nav-item .nav-link.active {
	background-color: #e9faec;
}

#sidebar .nav-link.active:before {
	display: none;
}

@media only screen and (min-width:991px) {
	.sidebar-open #sidebar {
		width: 17.5em;
	}
}

.side-nav {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	background: #dfe5eb;
	border: 1px solid #ccc;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
	height: 100%;
	padding: 0;
	position: fixed;
	top: 47px;
	z-index: 3;
	width: 256px;
	left: -200px;
	-webkit-transition-duration: 2s;
	transition-duration: 2s;
	display: none;
}

.side-nav .user-profile {
	display: none;
}

.side-nav ul li .navbar-icon {
	float: right;
	padding-top: 5px;
}

.side-nav ul li span {
	display: inline-block;
	min-width: 150px;
	padding-left: 10px;
}

@media only screen and (min-width:991px) {
	.side-nav {
		display: block;
	}
}

.side-nav ul {
	list-style: none;
	padding: 0;
}

.side-nav ul li {
	border-bottom: 1px solid #cdcdcd;
	padding: 0;
	cursor: pointer;
}

.side-nav ul li a {
	padding: 10px 20px 10px 5px;
	display: inline-block;
	width: 100%;
}

.side-nav ul li a.active {
	background: #fff;
	color: #004381;
}

.side-nav.open-side-menu {
	display: block;
	width: 256px;
	-webkit-transform: translate3d(200px,0,0);
	transform: translate3d(200px,0,0);
	-webkit-transition: all 0.3s linear;
	transition: all 0.3s linear;
}

.content-wrapper {
	margin: 60px 15px 0 15px;
	overflow-x: hidden;
}

@media only screen and (min-width:991px) {
	.content-wrapper {
		margin: 60px 15px 0 81px;
	}
}

@media only screen and (min-height:1300px) {
	.content-wrapper {
		min-height: 1032px;
	}
}

.content-wrapper.farmer-creation-screen {
	margin: 47px 15px 0 15px;
}

@media only screen and (min-width:991px) {
	.content-wrapper.farmer-creation-screen {
		margin: 47px 15px 0 81px;
	}

	.content-wrapper.farmer-creation-screen {
		margin: 47px 15px 0 81px;
	}
}

@media only screen and (min-height:500px) {
	.content-wrapper {
		min-height: 432px;
	}
}

@media only screen and (min-height:700px) {
	.content-wrapper {
		min-height: 635px;
	}
}

@media only screen and (min-height:850px) {
	.content-wrapper {
		min-height: 735px;
	}
}

@media only screen and (min-height:1000px) {
	.content-wrapper {
		min-height: 832px;
	}
}

@media only screen and (min-height:1300px) {
	.content-wrapper {
		min-height: 1032px;
	}
}

.content-wrapper.farmer-creation-screen {
	margin: 47px 15px 0 15px;
}

.dual-list[_ngcontent-c8] .button-bar[_ngcontent-c8] {
	margin-top: 8px;
	display: none;
}

.dual-list .listbox button.btn-block {
	margin-top: 0!important;
}

.dual-list .button-bar>button {
	display: none;
}

.content-wrapper .tab-control-div {
	position: fixed;
	top: 108px;
	left: 64px;
	right: 0;
	width: calc(100% - 64px);
	margin: 0!important;
	z-index: 99;
}

.content-wrapper .tab-control-div h2 {
	margin: 0 0 25px;
}

.content-wrapper .tab-content {
	margin-top: 155px;
	z-index: 1;
}

.content-wrapper.farmer-info {
	margin-top: 0;
}

.content-wrapper.farmer-info .btn-success {
	margin: 15px 0;
}

.content-wrapper.background {
	margin: 100px 0 0 65px;
}

@media only screen and (min-width:991px) {
	.content-wrapper.background {
		margin: 100px 15px 0 81px;
	}
}

.content-wrapper.background h2 {
	padding: 0 15px;
}

.content-wrapper.background .project-dashboard {
	background-color: #f7f7f7;
	padding: 0 20px;
}

.content-wrapper.background .project-dashboard img {
	border: 0;
	width: 100%;
	max-width: 250px;
	margin: 0 auto;
	display: block;
}

@media only screen and (min-width:991px) {
	.content-wrapper.background .project-dashboard img {
		margin: 0;
	}
}

.content-wrapper.background .project-dashboard img:focus {
	outline: 0;
}

.content-wrapper.background .project-dashboard h3 {
	text-align: left;
	text-transform: uppercase;
	color: #000;
	font-size: 25px;
	padding: 10px 0;
}

.content-wrapper.background .project-dashboard .project-desc {
	line-height: 30px;
	font-size: 16px;
	margin-bottom: 30px;
	margin-left: 0;
}

.content-wrapper.background .project-dashboard .project-image {
	margin-bottom: 30px;
}

.content-wrapper.background .project-dashboard .project-image img {
	padding: 0;
	max-width: 500px;
	height: auto;
}

.content-wrapper.background .myprojects {
	padding: 20px;
}

@media only screen and (min-width:767px) {
	.content-wrapper.background .myprojects {
		padding: 25px;
	}
}

@media only screen and (min-width:991px) {
	.content-wrapper.background .myprojects {
		padding: 15px 0;
	}
}

.content-wrapper.background .myprojects .form-control {
	height: 32px;
}

.content-wrapper.background .myprojects h3 {
	font-size: 1.3rem!important;
	color: #292929;
	display: block;
	border: none!important;
	font-weight: 700;
}

.content-wrapper.background .myprojects .border-bottom {
	padding: 0;
	margin: 0 0 15px;
}

.content-wrapper.background .myprojects .list-project {
	width: 100%;
	float: left;
	border-radius: 15px;
	background: #fff;
	padding-bottom: 50px;
}

.content-wrapper.background .myprojects .list-project ul {
	margin: 0;
	padding: 0;
}

.content-wrapper.background .myprojects .list-project ul li {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
	margin: 0 2% 30px 0;
	list-style: none;
	padding: 0;
	border-bottom: 1px solid #cdcdcd;
	border-left: 1px solid #cdcdcd;
	border-right: 1px solid #cdcdcd;
	float: left;
	-webkit-box-shadow: 0 2px 5px 0 rgba(255,255,255,.2),0 2px 8px 0 rgba(35,35,35,.19);
	box-shadow: 0 2px 5px 0 rgba(255,255,255,.2),0 2px 8px 0 rgba(35,35,35,.19);
	border-radius: 4px 4px 4px 4px;
}

.content-wrapper.background .myprojects .list-project ul li:hover {
	box-shadow: 0 1px 5px rgba(0,0,0,.25);
}

@media only screen and (min-width:991px) {
	.content-wrapper.background .myprojects .list-project ul li.col-md-4 {
		width: 24%;
		margin: 0 1.3% 20px 0;
	}

	.content-wrapper.background .myprojects .list-project ul li.col-md-4:nth-child(3n) {
		margin-right: 1.3%;
	}

	.content-wrapper.background .myprojects .list-project ul li.col-md-4:nth-child(4n) {
		margin-right: 0;
	}
}

@media only screen and (min-width:1200px) {
	.content-wrapper.background .myprojects .list-project ul li.col-lg-3 {
		width: 19.2%;
		margin: 0 1% 20px 0;
	}

	.content-wrapper.background .myprojects .list-project ul li.col-lg-3:nth-child(2n) {
		margin-right: 1%;
	}

	.content-wrapper.background .myprojects .list-project ul li.col-lg-3:nth-child(3n) {
		margin-right: 1%;
	}

	.content-wrapper.background .myprojects .list-project ul li.col-lg-3:nth-child(5n) {
		margin-right: 0;
	}
}

@media only screen and (min-width:1500px) {
	.content-wrapper.background .myprojects .list-project ul li.col-lg-3 {
		width: 15.4%;
		margin: 0 1.5% 20px 0;
	}

	.content-wrapper.background .myprojects .list-project ul li.col-lg-3:nth-child(2n) {
		margin-right: 1.5%;
	}

	.content-wrapper.background .myprojects .list-project ul li.col-lg-3:nth-child(3n) {
		margin-right: 1.5%;
	}

	.content-wrapper.background .myprojects .list-project ul li.col-lg-3:nth-child(4n) {
		margin-right: 1.5%;
	}

	.content-wrapper.background .myprojects .list-project ul li.col-lg-3:nth-child(5n) {
		margin-right: 1.5%;
	}

	.content-wrapper.background .myprojects .list-project ul li.col-lg-3:nth-child(6n) {
		margin-right: 0;
	}
}

.content-wrapper.background .myprojects .list-project ul li .box {
	border: 0;
	margin: 0;
}

.content-wrapper.background .myprojects .list-project ul li .box img {
	outline: 0;
	border: 0;
	margin: 0;
	padding: 0;
	height: 300px;
	width: 100%;
	border-radius: 4px 4px 0 0;
	animation: mdmicons 6s 1;
	--webkit-animation: mdmicons 6s 1;
}

@keyframes mdmicons {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@media only screen and (min-width:767px) {
	.content-wrapper.background .myprojects .list-project ul li .box img {
		height: 200px;
	}
}

.content-wrapper.background .myprojects .list-project ul li .box h3 {
	background: #fff;
	padding: 10px 0;
	overflow: hidden;
	line-height: 20px;
	text-overflow: ellipsis;
	max-height: 58px;
	min-height: 58px;
	font-size: 0.9em!important;
	line-clamp: 1;
	-webkit-line-clamp: 1;
	box-orient: vertical;
	-webkit-box-orient: vertical;
	word-break: break-word;
}

.content-wrapper.background .myprojects .list-project ul li .box p {
	padding: 10px;
	line-height: 25px;
	max-height: 90px;
	min-height: 90px;
	word-break: break-all;
	-webkit-box-orient: vertical;
	display: block;
	-webkit-line-clamp: 3;
	overflow: hidden;
	text-overflow: ellipsis;
}

.content-wrapper.background .actions .view-all {
	display: block;
	margin: 0 auto;
}

.content-wrapper.background .actions #showLess {
	display: none;
}

@media only screen and (min-width:767px) {
	.content-wrapper .farmer-form h2 {
		padding-right: 210px;
	}
}

.content-wrapper h2 {
	text-transform: capitalize;
	color: #a0a5a9;
	font-weight: 400;
	font-family: RobotoReg,segoe UI,Tahoma,Geneva,Verdana,sans-serif;
	font-size: 1.5rem!important;
}

.content-wrapper h2 span {
	color: #000;
	font-family: RobotoBold,segoe UI,Tahoma,Geneva,Verdana,sans-serif;
}

.content-wrapper #userCreation,.content-wrapper .farmer-profile,.content-wrapper .project-create {
	padding-bottom: 1.25rem;
}

.content-wrapper #userCreation .card .card-body .form-group .control-label span.desc,.content-wrapper .farmer-profile .card .card-body .form-group .control-label span.desc,.content-wrapper .project-create .card .card-body .form-group .control-label span.desc {
	color: #8c8c8c;
	font-size: 11.5px;
	font-weight: 400;
	line-height: normal;
}

.content-wrapper h3 {
	text-transform: uppercase;
	font-size: 20px;
	font-weight: 700;
}

.content-wrapper .partners p .checkbox-inline {
	width: 250px;
	margin-left: 0;
	margin-bottom: 20px;
}

.content-wrapper .service-mapping table thead tr th {
	background-color: #004281;
	color: #fff;
	text-align: center;
	vertical-align: middle;
}

.content-wrapper .service-mapping table tbody tr td {
	text-align: center;
	display: table-cell;
	min-width: 70px;
}

.content-wrapper .service-mapping table tbody tr td:first-child {
	text-align: left;
}

.content-wrapper .actions .btn-primary.btn {
	margin-right: 20px;
}

.project-info h3 {
	text-transform: uppercase;
	color: #000;
	font-size: 25px;
}

.project-info .accordion .title {
	margin-top: .75em;
	margin-bottom: .5em;
	font-size: 20px;
	color: #007bff;
}

.project-info .accordion .title:before {
	content: none;
}

.project-info .accordion .title span {
	position: relative;
	left: -20px;
}

.project-info .accordion .title span.down-arrow,.project-info .accordion .title span.up-arrow {
	fill: #007bff;
}

.project-info .accordion .title span.down-arrow svg,.project-info .accordion .title span.up-arrow svg {
	width: 15px;
}

.project-info .accordion .title.collapsed:before {
	content: none;
}

.project-info .accordion .title.collapsed span.up-arrow {
	display: none;
}

.project-info .accordion .title.collapsed span.down-arrow {
	display: inline-block;
}

.project-info .accordion .title span.down-arrow {
	display: none;
}

.project-info .accordion .title span.up-arrow {
	display: inline-block;
}

.project-info p {
	line-height: 30px;
	font-size: 14px;
	margin-bottom: 0;
	margin-left: 0;
	display: inline-block;
}

.project-info .project-details p {
	display: inline-block;
	width: 80%;
	margin-bottom: 20px;
}

.project-info .project-details h5 {
	font-size: 20px;
	font-weight: 700;
	text-decoration: underline;
	padding: 10px 0 20px;
	margin: 0;
}

.project-info .project-details table.table tr td {
	border-top: 0;
	min-width: 180px;
	padding: 5px;
	word-break: break-word;
}

.content-wrapper .farmprofit-form .card.dynamic .card-body .form-group,.content-wrapper .gap-form .card.dynamic .card-body .form-group,.content-wrapper .livelihood-form .card.dynamic .card-body .form-group {
	min-height: 90px;
}

.content-wrapper .farm-form .card.border-secondary .card-body .form-inline .form-group,.content-wrapper .farmer-form .card.border-secondary .card-body .form-inline .form-group,.content-wrapper .gap-form .card.border-secondary .card-body .form-inline .form-group,.content-wrapper .husbandry-form .card.border-secondary .card-body .form-inline .form-group,.content-wrapper .livelihood-form .card.border-secondary .card-body .form-inline .form-group,.content-wrapper .project-create .card.border-secondary .card-body .form-inline .form-group {
	margin-bottom: 0;
}

.content-wrapper .farm-form .addBtn,.content-wrapper .farmer-form .addBtn,.content-wrapper .gap-form .addBtn,.content-wrapper .husbandry-form .addBtn,.content-wrapper .livelihood-form .addBtn,.content-wrapper .project-create .addBtn {
	padding: 1px;
	width: 55px;
}

.content-wrapper .farm-form .width-50,.content-wrapper .farmer-form .width-50,.content-wrapper .gap-form .width-50,.content-wrapper .husbandry-form .width-50,.content-wrapper .livelihood-form .width-50,.content-wrapper .project-create .width-50 {
	width: 50px;
}

.content-wrapper .farm-form .form-group,.content-wrapper .farmer-form .form-group,.content-wrapper .gap-form .form-group,.content-wrapper .husbandry-form .form-group,.content-wrapper .livelihood-form .form-group,.content-wrapper .project-create .form-group {
	margin-bottom: 25px;
}

.content-wrapper .edit-btn {
	text-overflow: initial;
	width: auto;
}

.farmer-profile.personal {
	padding-top: 10px;
}

.farmer-profile.personal.farmer-form .personal-screen label.preview {
	display: block;
	color: #a2a2a2;
}

@media only screen and (min-width:991px) {
	.search-actions {
		margin-left: 0;
		padding: 29px 15px 15px;
	}
}

.search-actions .primary-button {
	margin-right: 20px;
}

.search-results .panel-group .panel .panel-body .divTable {
	min-width: 700px;
}

@media only screen and (min-width:767px) {
	.search-results .panel-group .panel .panel-body .divTable {
		width: 100%;
		min-width: auto;
	}
}

.search-results .panel-group .panel .panel-body .divTable .table-header {
	background: #004281;
	color: #fff;
	padding: 7px 10px;
}

.search-results .panel-group .panel .panel-body .divTable .sub-head-table {
	border-left: 1px solid #ccc;
	border-right: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
}

.search-results .panel-group .panel .panel-body .divTable .sub-head-table span {
	font-weight: 700;
	background: #dff6ff;
	display: block;
	padding: 5px;
}

.search-results .panel-group .panel .panel-body .divTable .content div.row-eq-height {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.search-results .panel-group .panel .panel-body .divTable .content div.row-eq-height div {
	display: inline-block;
	padding: 5px;
	border-right: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
}

.search-results .panel-group .panel .panel-body .divTable .content div.row-eq-height div.col-md-5 {
	width: 40%;
}

.search-results .panel-group .panel .panel-body .divTable .content div.row-eq-height div.col-md-1 {
	width: 10%;
}

.search-results .panel-group .panel .panel-body .divTable .content div.row-eq-height div.span20 {
	width: 20%;
	float: left;
}

.search-results .panel-group .panel .panel-body .divTable .content div.row-eq-height div:first-child {
	border-left: 1px solid #ccc;
}

.search-results .panel-group .panel .panel-body .divTable .content div.row-eq-height span.same-height {
	height: 100%;
	display: block;
	word-break: break-all;
}

.search-results .panel-group .panel .panel-body table .table-header {
	background: #004281;
	color: #fff;
	border: 1px solid #004281;
}

.search-results .panel-group .panel.optional-questions .panel-body p [type="checkbox"]:checked,.search-results .panel-group .panel.optional-questions .panel-body p [type="checkbox"]:not(:checked) {
	position: absolute;
	left: -9999px;
}

.search-results .panel-group .panel.optional-questions .panel-body p [type="checkbox"]:checked+label,.search-results .panel-group .panel.optional-questions .panel-body p [type="checkbox"]:not(:checked)+label {
	position: relative;
	padding-left: 1.95em;
	cursor: pointer;
}

.search-results .panel-group .panel.optional-questions .panel-body p [type="checkbox"]:checked+label:before,.search-results .panel-group .panel.optional-questions .panel-body p [type="checkbox"]:not(:checked)+label:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 1.25em;
	height: 1.25em;
	border: 2px solid #ccc;
	background: #fff;
	border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
	box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
}

.search-results .panel-group .panel.optional-questions .panel-body p [type="checkbox"]:checked+label:after,.search-results .panel-group .panel.optional-questions .panel-body p [type="checkbox"]:not(:checked)+label:after {
	content: "\2713\0020";
	position: absolute;
	top: .15em;
	left: .22em;
	font-size: 1.3em;
	line-height: .8;
	color: #09ad7e;
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
}

.search-results .panel-group .panel.optional-questions .panel-body p [type="checkbox"]:not(:checked)+label:after {
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
}

.search-results .panel-group .panel.optional-questions .panel-body p [type="checkbox"]:checked+label:after {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}

.search-results .panel-group .panel.optional-questions .panel-body p [type="checkbox"]:disabled:checked+label:before,.search-results .panel-group .panel.optional-questions .panel-body p [type="checkbox"]:disabled:not(:checked)+label:before {
	-webkit-box-shadow: none;
	box-shadow: none;
	border-color: #bbb;
	background-color: #ddd;
}

.search-results .panel-group .panel.optional-questions .panel-body p [type="checkbox"]:disabled:checked+label:after {
	color: #999;
}

.search-results .panel-group .panel.optional-questions .panel-body p [type="checkbox"]:disabled+label {
	color: #aaa;
}

.search-results .panel-group .panel.optional-questions .panel-body p [type="checkbox"]:checked:focus+label:before,.search-results .panel-group .panel.optional-questions .panel-body p [type="checkbox"]:not(:checked):focus+label:before {
	border: 2px dotted #00f;
}

.search-results .panel-group .panel.optional-questions .panel-body p label:hover:before {
	border: 2px solid #4778d9!important;
}

.questionnarie .alert.alert-danger {
	margin: 20px 0;
}

.farmer-info .dataTables_wrapper .dataTables_info,.farmer-info .dataTables_wrapper .dataTables_length,.user-listing .dataTables_wrapper .dataTables_info,.user-listing .dataTables_wrapper .dataTables_length {
	float: left;
}

.farmer-info .dataTables_wrapper .dataTables_filter,.farmer-info .dataTables_wrapper .dataTables_paginate,.user-listing .dataTables_wrapper .dataTables_filter,.user-listing .dataTables_wrapper .dataTables_paginate {
	float: right;
}

.farmer-info .dataTables_wrapper .dataTables_filter input,.farmer-info .dataTables_wrapper .dataTables_paginate input,.user-listing .dataTables_wrapper .dataTables_filter input,.user-listing .dataTables_wrapper .dataTables_paginate input {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border: 1px solid #cdcdcd;
	border-radius: 3px;
	margin: 0 0 0 20px;
}

.farmer-info .dataTables_wrapper .table,.user-listing .dataTables_wrapper .table {
	width: 100%!important;
}

.farmer-info .dataTables_wrapper .table td,.farmer-info .dataTables_wrapper .table th,.user-listing .dataTables_wrapper .table td,.user-listing .dataTables_wrapper .table th {
	width: 14.285%!important;
}

.farmer-info .dataTables_wrapper .table td.dateTime,.farmer-info .dataTables_wrapper .table th.dateTime,.user-listing .dataTables_wrapper .table td.dateTime,.user-listing .dataTables_wrapper .table th.dateTime {
	width: 16.285%!important;
}

@media only screen and (min-width:991px) {
	.farmer-info .dataTables_wrapper .table td.dateTime,.farmer-info .dataTables_wrapper .table th.dateTime,.user-listing .dataTables_wrapper .table td.dateTime,.user-listing .dataTables_wrapper .table th.dateTime {
		width: 14.285%!important;
	}
}

.farmer-info .dataTables_wrapper .table td a.label,.farmer-info .dataTables_wrapper .table th a.label,.user-listing .dataTables_wrapper .table td a.label,.user-listing .dataTables_wrapper .table th a.label {
	display: inline-block;
	padding: 3px 5px;
	margin-right: 0;
}

.farmer-info .dataTables_wrapper .paginate_button,.user-listing .dataTables_wrapper .paginate_button {
	cursor: pointer;
}

.farmer-info .dataTables_wrapper .paginate_button.current,.user-listing .dataTables_wrapper .paginate_button.current {
	border: 1px solid #004281;
	background: #004281;
	color: #fff;
	padding: 5px 8px;
}

.farmer-info .dataTables_wrapper .table td a.label {
	margin-right: 5px;
}

.farmer-profile.personal .personal-screen .form-inline label.control-label {
	margin-bottom: 5px;
}

.farmer-profile.personal .personal-screen .card .card-body.separator .form-inline {
	margin-bottom: 10px;
}

.farmer-profile.personal .personal-screen .width-46 {
	max-width: 300px;
	margin: 0 10px;
}

.farmer-profile.personal .personal-screen .crop-name-margin-10 {
	margin-bottom: 10px;
}

.farmer-profile.personal .progress-profile-bar {
	text-align: center;
}

.farmer-profile.personal .progress-profile-bar .a-progress,.farmer-profile.personal .progress-profile-bar .c-progress,.farmer-profile.personal .progress-profile-bar .e-progress,.farmer-profile.personal .progress-profile-bar .p-progress,.farmer-profile.personal .progress-profile-bar .s-progress {
	height: 100px;
}

.farmer-form .card .card-header h4,.farmprofit-form .card .card-header h4,.gap-form .card .card-header h4,.husbandry-form .card .card-header h4,.livelihood .card .card-header h4 {
	margin: 0;
}

.farmer-creation-screen .nav-tabs li.active>a,.farmer-creation-screen .nav-tabs li.active>a:focus,.farmer-creation-screen .nav-tabs li.active>a:hover {
	color: #fff;
	background-color: #004281;
}

.farmer-creation-screen .nav-tabs {
	border-bottom: 2px solid #004281;
}

.farmer-creation-screen .tab-content .tab-pane form div .card.margin-space {
	margin-bottom: 70px;
}

.farmer-creation-screen .tab-content .tab-pane.active .actions {
	display: block;
	position: relative;
	text-align: right;
	background: #fff;
	width: 100%;
	padding: 20px 20px;
	border-top: 1px solid #cdcdcd;
	margin: 0;
}

ul.nav.alert-msg>li>a:focus,ul.nav.alert-msg>li>a:hover {
	background: 0 0;
}

.disabled {
	cursor: not-allowed!important;
	background: #d3d3d3!important;
	pointer-events: none;
}

.disabled i {
	cursor: not-allowed;
}

.current span {
	color: #fefefe;
}

#userImportLabel {
	display: inline;
}

#userImportSearch {
	width: inherit;
	float: right;
}

.form-control {
	box-shadow: none;
	padding: 6px 12px;
	font-size: 14px;
	border: 1px solid #cdcdcd;
	background-color: transparent;
	border-radius: 3px;
	height: 32px;
}

.form-control:focus {
	border-color: #61765b!important;
	background-color: transparent;
	box-shadow: 0 0 5px #91ca7f!important;
}

.modal-body select.form-control {
	height: 34px!important;
}

.fa:before {
	font-family: FontAwesome;
}

.content-wrapper input[type="date"]:after {
	content: ""!important;
}

.form-check .form-check-input {
	position: relative;
	top: .1em;
	z-index: 99;
	width: 15px;
	height: 15px;
	left: 19px;
	opacity: 0;
	display: inline-block;
}

.form-control.others {
	margin-top: 5px;
}

.actions {
	margin-bottom: 20px;
}

.btn {
	cursor: pointer!important;
	background-color: #65B35C;
	border-color: #65B35C;
	padding: 6px 12px;
	border-radius: 4px;
	color: #fff;
}

.btn:active,.btn:focus {
	background-color: #3b9d31!important;
	border-color: #3b9d31!important;
	box-shadow: none!important;
}

.btn:hover {
	background-color: #3b9d31;
	border-color: #3b9d31;
}

.btn.btn-link {
	color: #fff;
}

.mandatory {
	color: red;
}

.primary-button.btn {
	background: #004281;
	color: #fff;
	width: 100px;
}

.secondary-button.btn {
	background: #fff;
	color: #004281;
	border: 1px solid #004281;
	width: 100px;
}

::-webkit-input-placeholder {
	color: #333;
	opacity: 1;
}

:-ms-input-placeholder {
	color: #333;
	opacity: 1;
}

::-ms-input-placeholder {
	color: #333;
	opacity: 1;
}

::placeholder {
	color: #333;
	opacity: 1;
}

:-ms-input-placeholder {
	color: #333;
}

::-ms-input-placeholder {
	color: #333;
}

.clearfix {
	clear: both;
	float: none;
}

.fltRgt {
	float: right;
}

.fltLft {
	float: left;
}

.padding-20 {
	padding: 20px;
}

h1,h2,h3,h4,h5,h6 {
	font-family: DINPro,roboto,arial,sans-serif;
	color: #004281;
	word-break: break-word;
}

h1 {
	font-size: 3.157em!important;
	line-height: 48px;
}

h2,h2 a {
	font-size: 2em!important;
	margin: 20px 0 0;
}

@media only screen and (min-width:991px) {
	h2,h2 a {
		font-size: 2.313em!important;
	}
}

h2 a,h2 span {
	font-size: 1.5rem!important;
}

h3 {
	font-size: 1.75em!important;
	line-height: 32px;
	margin-top: 20px;
}

h4 {
	font-size: 1.312em!important;
	line-height: 24px;
}

p {
	word-break: break-word;
}

a,div,input,li,p,select,span,table,td,textarea,th,tr,ul {
	font-size: 14px;
	line-height: 24px;
	color: #404040;
	font-family: "DM Sans",Verdana!important;
}

.btn,.form-group {
	font-size: 14px;
}

hr {
	margin: 0;
}

.margin-btm-20 {
	margin-bottom: 20px;
}

.margin-btm-10 {
	margin-bottom: 10px;
}

.margin-rgt-10 {
	margin-right: 10px;
}

.border-grey {
	border: 1px solid #cdcdcd;
}

.huge {
	font-size: 24px;
	font-weight: 700;
	padding: 5px;
}

.error {
	color: #ef001d;
	font-size: 12.5px;
	width: 100%;
	line-height: normal;
	text-align: left;
	display: inline-block;
}

.error-table {
	color: #ef001d;
	position: relative;
}

.transparent:before {
	color: transparent;
}

.disabledDate {
	cursor: not-allowed;
	pointer-events: none;
	opacity: .6;
}

.field-name,.field-value {
	display: inline-block;
	max-width: 400px;
	margin-right: 15px;
}

.form-group .input-group {
	margin-bottom: 10px;
}

.shift-down {
	margin-top: 40px;
}

textarea {
	resize: none;
}

.multiselect-dropdown .dropdown-btn {
	background: #fff;
	border: 1px solid #cdcdcd!important;
	padding: 5px 10px!important;
	height: 39px!important;
}

.multiselect-dropdown .dropdown-btn .dropdown-down {
	border-top: 5px solid #404040!important;
	border-left: 4px solid transparent!important;
	border-right: 4px solid transparent!important;
}

.multiselect-dropdown .dropdown-btn .dropdown-up {
	border-bottom: 5px solid #404040!important;
	border-left: 4px solid transparent!important;
	border-right: 4px solid transparent!important;
}

.multiselect-dropdown .dropdown-btn .selected-item {
	border: 1px solid #004281!important;
	background: #004281!important;
	border-radius: none!important;
}

.other-text {
	margin-top: 10px;
}

.flex-label {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.multiselect-dropdown .dropdown-list {
	z-index: 1!important;
}

.project-create .units.form-group .control-label {
	display: inline-block;
}

.project-create .units.form-group .table td,.project-create .units.form-group .table th {
	padding: 10px 6px;
}

.project-create .units.form-group .table tbody tr:first-child td {
	border: 1px solid #ddd;
}

#userCreation,.agro-screen,.farmer-profile,.modal,.project-create {
	padding-bottom: 1.25rem;
}

#userCreation.personal input,#userCreation.personal select,.agro-screen.personal input,.agro-screen.personal select,.farmer-profile.personal input,.farmer-profile.personal select,.modal.personal input,.modal.personal select,.project-create.personal input,.project-create.personal select {
	min-width: 100px!important;
}

#userCreation.personal input[type="radio"],.agro-screen.personal input[type="radio"],.farmer-profile.personal input[type="radio"],.modal.personal input[type="radio"],.project-create.personal input[type="radio"] {
	min-width: auto!important;
}

#userCreation.personal label,.agro-screen.personal label,.farmer-profile.personal label,.modal.personal label,.project-create.personal label {
	font-size: 14px;
}

.project-create .units input.width-measure {
	width: 80px;
	padding: 4px 3px;
	margin-right: 3px;
	display: inline-block;
}

@media only screen and (min-width:768px) {
	.project-create .units input.width-measure {
		width: 50px;
	}
}

@media only screen and (min-width:991px) {
	.project-create .units input.width-measure {
		width: 80px;
	}

	.chartbox {
		width: 500px;
		margin: 0 3% auto;
	}
}

@media only screen and (min-width:1500px) {
	.project-create .units input.width-measure {
		width: 100px;
	}
}

.project-create .units input .unit-name {
	padding-left: 3px;
	font-weight: 700;
}

.tab-only.clearfix {
	clear: unset;
	float: unset;
	display: none;
}

@media only screen and (min-width:767px) and (max-width:1199px) {
	.tab-only.clearfix {
		clear: both;
		float: none;
		display: block;
	}

	.small-anchor-tags a {
		display: inline-block!important;
	}

	.justify-content-center-tab {
		justify-content: center!important;
	}
}

.desktop-only.clearfix {
	clear: unset;
	float: unset;
	display: none;
}

@media only screen and (min-width:1200px) {
	.desktop-only.clearfix {
		clear: both;
		float: none;
		display: block;
	}
}

#img-upload,#qr-code {
	height: 100px;
	width: 100px;
	width: auto;
	position: relative;
	border: 1px solid #ccc;
	margin-bottom: 15px;
	z-index: 1;
}

@media only screen and (max-width:767px) {
	.image-preview,.qr-code {
		width: 150px;
		display: inline-block;
	}
}

label.space-below {
	display: block;
	margin-bottom: 29px!important;
}

.nav-pills>li>a>.badge,.nav-pills>li>a>.label,.nav-tabs>li>a>.badge,.nav-tabs>li>a>.label {
	position: relative;
	background-color: rgba(0,0,0,.25);
	border: 1px solid transparent;
	margin: 0 -5px -100px 5px;
	padding: 0 6px;
	line-height: 14px;
	top: 0;
}

.nav-pills>li>a>.label,.nav-tabs>li>a>.label {
	padding-left: 4px;
	padding-right: 4px;
}

.nav-pills>li>a>.badge.badge-success,.nav-pills>li>a>.label.label-success,.nav-tabs>li>a>.badge.badge-success,.nav-tabs>li>a>.label.label-success {
	background-color: #5ebd5e;
}

.nav-pills>li>a>.badge.badge-warning,.nav-pills>li>a>.label.label-warning,.nav-tabs>li>a>.badge.badge-warning,.nav-tabs>li>a>.label.label-warning {
	background-color: #f4b04f;
}

.nav-pills>li>a>.badge.badge-danger,.nav-pills>li>a>.label.label-danger,.nav-tabs>li>a>.badge.badge-danger,.nav-tabs>li>a>.label.label-danger {
	background-color: #e66454;
}

.nav-pills>li>a>.badge.badge-info,.nav-pills>li>a>.label.label-info,.nav-tabs>li>a>.badge.badge-info,.nav-tabs>li>a>.label.label-info {
	background-color: #5bc0de;
}

.nav-pills>li.active>a>.badge,.nav-pills>li.active>a>.label,.nav-tabs:not(.nav-tabs-simple)>li.active>a>.badge,.nav-tabs:not(.nav-tabs-simple)>li.active>a>.label {
	background-color: rgba(0,0,0,.25);
	border-color: transparent;
	color: #fff;
}

.nav-pills.nav-justified>li>a,.nav-tabs.nav-justified>li>a {
	margin: 0;
}

.nav-tabs.nav-tabs-sm>li>a>.badge,.nav-tabs.nav-tabs-sm>li>a>.label {
	margin-left: 4px;
	margin-right: -4px;
}

.nav-tabs.nav-tabs-xs>li>a {
	font-size: 12px;
}

.nav-tabs.nav-tabs-xs>li>a>.badge,.nav-tabs.nav-tabs-xs>li>a>.label {
	padding-left: 4px;
	padding-right: 4px;
	line-height: 13px;
	font-size: 10px;
	margin-left: 3px;
	margin-right: -3px;
	top: -1px;
}

.nav-tabs.nav-tabs-xs>li>a>.label {
	padding-left: 3px;
	padding-right: 3px;
}

.nav-tabs {
	border-bottom: 2px solid #e4e4e4;
}

.nav-tabs>li>a {
	background-color: #f3f3f3;
	background-color: rgba(0,0,0,.05);
	border: 0;
	border-bottom: 2px solid #e4e4e4;
	color: #777;
	margin: 0 6px -1px 0;
	padding: 8px 20px;
	text-transform: capitalize;
}

.nav-tabs>li>a:active,.nav-tabs>li>a:hover {
	background-color: rgba(0,0,0,.06);
	border-bottom-color: #d7d7d7;
	color: #555;
}

.nav-tabs>li>a>.fa-caret-down {
	font-size: 12px;
}

.nav-tabs>li.dropdown.open>a:focus {
	border-bottom: 2px solid #e4e4e4;
}

.nav-tabs li.active>a,.nav-tabs li.active>a:focus,.nav-tabs li.active>a:hover {
	border: none;
	color: #fff;
}

.nav-tabs.nav-justified>li>a {
	border-bottom: 2px solid #e4e4e4;
	border-left: none!important;
	border-right: none!important;
	border-top: none!important;
}

.nav-tabs.nav-stacked>li>a {
	margin: 0;
	border-bottom-width: 1px!important;
}

.nav-tabs.nav-stacked>li:last-child>a {
	border-bottom-width: 0!important;
}

.nav-tabs.nav-stacked>li:last-child.active>a {
	border-bottom-width: 2px!important;
	margin-bottom: -3px;
}

.nav-tabs.nav-stacked>li:not(:first-child):not(:last-child)>a {
	border-radius: 0;
}

.nav-tabs.nav-tabs-simple>li>a {
	background-color: transparent!important;
	margin-right: 0;
}

.nav-tabs.nav-tabs-simple>li.active>a {
	color: #555!important;
}

.nav-tabs.nav-tabs-sm>li>a {
	padding: 6px 15px;
}

.nav-tabs.nav-tabs-xs>li>a {
	padding: 4px 15px;
}

.tab-content {
	padding: 15px 0;
}

.tab-content.tab-content-bordered {
	border: 1px solid #d7d7d7;
	border-top: none;
	padding-left: 15px;
	padding-right: 15px;
	border-bottom-right-radius: 2px;
	border-bottom-left-radius: 2px;
}

.nav-pills>li>a {
	color: #777;
	background-color: #f3f3f3;
	background-color: rgba(0,0,0,.05);
	border-radius: 2px;
	padding: 8px 20px;
}

.nav-pills>li>a:hover {
	background-color: rgba(0,0,0,.06);
	color: #555;
}

.nav-pills>li>a>.fa-caret-down {
	font-size: 12px;
}

.nav-pills>li>a>.badge,.nav-pills>li>a>.label {
	top: -1px;
}

.nav-pills>li {
	margin-left: 0;
}

.nav-pills>li+li {
	margin-left: 0;
}

.nav-pills.nav-stacked>li {
	margin: 0 0 4px;
}

.dropdown-menu {
	border: 2px solid #ccc;
	-webkit-box-shadow: none;
	box-shadow: none;
}

ul.dropdown-menu {
	border: 2px solid #ccc;
	padding: 5px 0;
}

ul.dropdown-menu>li>a>.badge,ul.dropdown-menu>li>a>.label {
	position: relative;
	padding-left: 4px;
	padding-right: 4px;
	line-height: 13px;
	font-size: 10px;
	top: 1px;
}

ul.dropdown-menu>li>a>.label {
	padding-top: 1px;
	padding-bottom: 1px;
}

ul.dropdown-menu .divider {
	border-width: 2px;
	margin: 5px 0;
}

ul.dropdown-menu .dropdown-header {
	font-size: 11px;
	font-weight: 600;
	margin: 0 -2px;
	padding: 6px 18px 3px;
	text-transform: uppercase;
}

ul.dropdown-menu .dropdown-icon {
	display: inline-block;
	font-size: 14px;
}

.nav .dropdown>.dropdown-toggle .nav-icon {
	font-size: 12px;
	display: inline-block;
	margin-right: 8px;
}

.nav-pills,.nav-tabs {
	position: relative;
}

.tabdrop {
	margin-left: 0!important;
	margin-right: 0!important;
}

.tabdrop>.dropdown-toggle {
	cursor: pointer!important;
	margin-right: 0;
	padding-left: 12px;
	padding-right: 12px;
}

.tabdrop>.dropdown-toggle>.fa {
	font-size: 14px!important;
	margin: 0!important;
	padding: 0!important;
}

.tabdrop>.dropdown-toggle>.caret {
	border: none;
	display: inline-block;
	font-family: FontAwesome;
	font-size: 10px;
	height: auto;
	margin-left: 5px;
	position: relative;
	top: -1px;
	width: auto;
}

.tabdrop>.dropdown-toggle>.caret:after {
	content: "";
}

.nav-tabs .tabdrop.active a span {
	color: #fff;
}

.agro-screen .nav-tabs>li.active>a,.agro-screen .nav-tabs>li.active>a:focus,.agro-screen .nav-tabs>li.active>a:hover {
	background: #004281;
	border-bottom: 2px solid #004281;
}

.agro-screen .nav-tabs .nav-tabs.nav-justified>.active>a {
	border-bottom-color: #004281;
}

.agro-screen .dropdown-menu>li.active>a,.agro-screen .dropdown-menu>li>a:hover,.agro-screen .nav-pills>li.active>a,.agro-screen .nav-pills>li.active>a:focus,.agro-screen .nav-pills>li.active>a:hover {
	background: #004281;
}

.agro-screen .dropdown-menu>li>a:hover,.white {
	color: #fff;
}

.infographic-box {
	padding: 20px;
}

.main-box {
	background: #fff;
	-webkit-box-shadow: 1px 1px 2px 0 #ccc;
	box-shadow: 1px 1px 2px 0 #ccc;
	margin-bottom: 16px;
	border-radius: 3px;
	background-clip: padding-box;
}

.infographic-box i {
	font-size: 2.4em;
	display: block;
	float: left;
	margin-right: 15px;
	width: 60px;
	height: 60px;
	line-height: 60px;
	text-align: center;
	border-radius: 50%;
	background-clip: padding-box;
	color: #fff;
}

.red-bg {
	background-color: #e74c3c!important;
}

.infographic-box .headline {
	display: block;
	font-size: 1.2em;
	font-weight: 300;
	text-align: right;
}

.infographic-box .value {
	font-size: 2.1em;
	font-weight: 600;
	margin-top: -5px;
	display: block;
	text-align: right;
}

.infographic-box .value {
	font-size: 2.1em;
	font-weight: 600;
	margin-top: -5px;
	display: block;
	text-align: right;
}

.main-box {
	background: #fff;
	-webkit-box-shadow: 1px 1px 2px 0 #ccc;
	box-shadow: 1px 1px 2px 0 #ccc;
	margin: 15px 25px 16px 25px;
	border-radius: 3px;
	background-clip: padding-box;
	border: 1px solid #ccc;
}

.historyData div img {
	width: 100%;
}

.hide-menu {
	background-color: #336ca6;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
	width: 300px;
	height: 100%;
	-webkit-transform: translate3d(300px,0,0);
	transform: translate3d(300px,0,0);
	-webkit-transition: all 0.3s linear;
	transition: all 0.3s linear;
}

.show-menu {
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

#loader-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
}

#loader {
	display: block;
	position: relative;
	left: 50%;
	top: 50%;
	width: 150px;
	height: 150px;
	margin: -75px 0 0 -75px;
	border-radius: 50%;
	border: 3px solid transparent;
	border-top-color: #3498db;
	-webkit-animation: spin 2s linear infinite;
	animation: spin 2s linear infinite;
	z-index: 1001;
}

#loader:before {
	content: "";
	position: absolute;
	top: 5px;
	left: 5px;
	right: 5px;
	bottom: 5px;
	border-radius: 50%;
	border: 3px solid transparent;
	border-top-color: #e74c3c;
	-webkit-animation: spin 3s linear infinite;
	animation: spin 3s linear infinite;
}

#loader:after {
	content: "";
	position: absolute;
	top: 15px;
	left: 15px;
	right: 15px;
	bottom: 15px;
	border-radius: 50%;
	border: 3px solid transparent;
	border-top-color: #f9c922;
	-webkit-animation: spin 1.5s linear infinite;
	animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}

	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}

	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

#loader-wrapper .loader-section {
	position: fixed;
	top: 0;
	width: 51%;
	height: 100%;
	background: #222;
	z-index: 1000;
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

#loader-wrapper .loader-section.section-left {
	left: 0;
}

#loader-wrapper .loader-section.section-right {
	right: 0;
}

.loaded #loader-wrapper .loader-section.section-left {
	-webkit-transform: translateX(-100%);
	transform: translateX(-100%);
	-webkit-transition: all 0.7s 0.3s cubic-bezier(.645,.045,.355,1);
	transition: all 0.7s 0.3s cubic-bezier(.645,.045,.355,1);
}

.loaded #loader-wrapper .loader-section.section-right {
	-webkit-transform: translateX(100%);
	transform: translateX(100%);
	-webkit-transition: all 0.7s 0.3s cubic-bezier(.645,.045,.355,1);
	transition: all 0.7s 0.3s cubic-bezier(.645,.045,.355,1);
}

.loaded #loader {
	opacity: 0;
	-webkit-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}

.loaded #loader-wrapper {
	visibility: hidden;
	-webkit-transform: translateY(-100%);
	transform: translateY(-100%);
	-webkit-transition: all 0.3s 1s ease-out;
	transition: all 0.3s 1s ease-out;
}

.no-js #loader-wrapper {
	display: none;
}

.invalid-feedback span {
	color: #ef001d!important;
}

.modal-backdrop {
	z-index: 100;
	min-height: 500vh;
}

.modal-dialog {
	z-index: 150;
}

table thead th {
	font-family: RobotoBold;
}

.upload_file {
	position: absolute;
	top: 10px;
	margin-left: 10px;
	font-size: 1.5em;
}

.bold input {
	font-weight: 700;
	color: #000!important;
}

.testclass {
	display: none;
}

@media only screen and (min-device-width:991px) and (max-device-width:1024px) {
	div ul.fixed-tabs {
		left: 64px;
	}

	div ul.sub-tab-fixed {
		left: 67px;
	}

	div.select-chart-wizard {
		left: 81px;
	}

	div.project-header {
		padding-left: 79px;
	}

	div.content-wrapper.background {
		margin-left: 66px;
	}

	div.project-list {
		padding-left: 64px;
	}

	div ul.fixed-tabs li {
		width: auto;
	}

	.form-design .fixed-actions {
		left: 0;
	}

	.farmer-profile-div .nav-pills li a {
		padding: 6px 30px;
	}
}

@media only screen and (min-device-width:830px) and (max-device-width:991px) {
	.project-list {
		padding-left: 0;
	}

	div ul.fixed-tabs {
		left: 0;
		width: auto;
	}

	div ul.sub-tab-fixed {
		left: 26px;
	}

	.project-header {
		padding-left: 20px;
	}

	.content-wrapper.background {
		margin-left: 20px;
	}

	.project-info .card-body {
		height: auto;
	}

	.prev-btn {
		left: 0;
	}

	div ul.fixed-tabs li {
		width: auto;
	}

	.farmer-profile-div .nav-pills li a {
		padding: 6px 30px;
	}

	.form-design .fixed-actions {
		left: 0;
	}
}

@media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation:portrait) {
	.project-list {
		padding-left: 0;
	}

	.peoject-img-list {
		width: 32%;
	}

	.project-right-area {
		width: 66%;
	}

	.project-right-area p {
		margin-bottom: 8px;
		line-height: normal;
	}

	.service-name {
		font-size: .7rem;
		margin-top: 6px;
	}

	.project-header {
		padding-left: 15px;
	}

	.project-header .form-control {
		padding: 5px 12px!important;
		margin-top: 4px;
	}

	.project-header .form-group-sm .input-group .input-group-icon {
		margin-top: 4px;
		height: 33px;
	}

	.project-table-data .table tbody tr td {
		font-size: .6rem;
		padding: .15rem .6rem;
		line-height: 15px;
	}

	.project-para {
		height: auto;
		margin-bottom: 0;
	}

	.message-text p {
		font-size: 1.65rem;
		width: 90%;
	}

	div.content-wrap {
		margin-top: 136px;
		padding-bottom: 80px;
	}

	.partner-services-list .service-image {
		width: 80px;
	}

	form.form-design {
		padding-bottom: 30px;
	}

	.user-dropdown ul.dropdown-menu {
		left: -5px;
	}

	.content-wrapper.background .myprojects .list-project ul li {
		margin: 0 2% 30px 0;
		max-width: 50%;
		width: 31%;
		float: left;
		flex: inherit;
	}

	.content-wrapper.background {
		margin-left: 0;
		padding: 0 15px 25px 15px;
	}

	.form-design .fixed-actions {
		left: 0;
		width: 100%;
	}

	ul.fixed-tabs {
		left: 0;
		width: 100%;
	}

	.nav-pills:before {
		width: 100vw;
	}

	.bg {
		width: 100%;
		position: absolute;
		top: 0;
	}

	.login-right {
		width: 100%;
		z-index: 99;
		text-align: center;
	}

	.login-screen,.reset-screen {
		margin: 30% auto 0 auto;
		background: rgba(255,255,255,.95);
	}

	.welcome-deeproots {
		display: none;
	}

	.login-tab-header {
		display: block;
	}

	.language-changes {
		top: -63px;
		left: 30px;
		margin: inherit;
	}

	.questionnaire-tabs .form-group {
		height: auto;
	}

	.project-info .card-body {
		height: auto;
		padding-bottom: 15px;
	}

	.service-image {
		padding: 7px 11px;
		max-width: 100px;
		min-width: 100px;
	}

	ul.sub-tab-fixed {
		left: 0;
		margin-left: 26px!important;
		margin-right: 26px!important;
	}

	ul.fixed-tabs li {
		width: auto;
	}

	.farmer-profile-div .nav-pills li a {
		padding: 8px 20px;
	}

	.form-design .farmer-tab-content .form-group {
		min-height: auto;
	}

	.form-design {
		padding-bottom: 20px;
	}

	.profiling-screen .form-box {
		padding: 0;
	}

	.profiling-screen {
		min-height: 80vh;
	}

	.datatable-header select.form-control {
		width: 38px!important;
		padding: 0!important;
		text-align: center;
	}

	.datatable-header input.form-control {
		width: 106px!important;
	}

	#sidebar .navbar-collapse {
		width: 43%;
	}

	.datasheet-table {
		margin: 0;
	}

	.view-profile-data ul li .nested-ul li span {
		width: 47%;
	}

	.peoject-img-list img {
		min-height: 165px;
	}

	.next-btn {
		line-height: 43px;
	}

	.prev-btn {
		line-height: 43px;
	}

	.make-revision-btn {
		margin-top: 10px;
	}

	.select-chart-wizard {
		bottom: 50px;
		z-index: 99;
		left: 30px;
	}

	.ecosystem-location {
		width: 100%;
		height: auto;
	}

	.login-p2froots-img {
		display: none;
	}

	.login-p2froots-img-small {
		display: inline-block;
	}

	.login-p2froots-logo {
		width: 100%;
	}
}

@media only screen and (min-device-width:768px) and (max-device-width:1023px) and (orientation:portrait) {
	.navbar-nav {
		height: 100%;
		position: relative;
	}

	.datasheet-farmer-img {
		width: 15%;
	}

	.table tbody tr td {
		padding: 2px 6px!important;
		font-size: .7rem;
	}

	.table thead tr th {
		padding: 2px 6px!important;
		font-size: .75rem;
	}

	.edit-btn img {
		height: 11px;
	}

	.view-btn img {
		height: 11px;
	}

	.profile-completed {
		height: 25px;
		width: 25px;
		line-height: 20px;
	}

	.prev-btn {
		left: 0;
	}
}

@media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation:landscape) {
	.peoject-img-list {
		width: 25.6%;
	}

	.project-right-area {
		width: 73%;
	}

	.project-right-area p {
		margin-bottom: 8px;
		line-height: normal;
	}

	.service-name {
		font-size: .7rem;
		margin-top: 6px;
	}

	.project-header .form-control {
		padding: 5px 12px!important;
		margin-top: 4px;
	}

	.project-header .form-group-sm .input-group .input-group-icon {
		margin-top: 4px;
		height: 33px;
	}

	.project-table-data .table tbody tr td {
		font-size: .6rem;
		padding: .15rem .6rem;
		line-height: 15px;
	}

	.project-para {
		height: auto;
		margin-bottom: 0;
	}

	.message-text p {
		font-size: 1.65rem;
		width: 90%;
	}

	div.content-wrap {
		margin-top: 136px;
	}

	.partner-services-list .service-image {
		width: 80px;
	}

	form.form-design {
		padding-bottom: 80px;
	}

	.user-dropdown ul.dropdown-menu {
		left: -5px;
	}

	.content-wrapper.background .myprojects .list-project ul li {
		margin: 0 2% 30px 0;
		max-width: 50%;
		width: 31%;
		float: left;
		flex: inherit;
	}

	.nav-pills:before {
		width: 100vw;
	}

	.bg {
		width: 100%;
		position: absolute;
		top: 0;
		padding-bottom: 40px;
		background-image: url(../images/login-bg-plain.webp);
		background-size: cover;
	}

	.login-right {
		width: 100%;
		z-index: 99;
		text-align: center;
	}

	.login-screen,.reset-screen {
		margin: 15% auto 0 auto;
		padding: 5px 15px;
		width: 70%;
	}

	.welcome-deeproots {
		display: none;
	}

	.login-tab-header {
		display: block;
		padding: 6px 20px;
	}

	.language-changes {
		top: -52px;
		left: 30px;
		margin: inherit;
	}

	.login-tab-header img {
		width: 140px;
	}

	.login-screen p {
		line-height: normal;
		margin-bottom: 20px;
	}

	div.make-revision-btn .btn-secondary {
		padding: 6px 8px;
	}

	.project-info .card-body {
		height: 205px;
	}

	ul.sub-tab-fixed {
		width: 100%;
	}

	.service-image {
		max-width: 100px;
		min-width: 100px;
	}

	.error-template {
		margin-left: 50px;
	}

	.login-p2froots-img {
		display: none;
	}

	.login-p2froots-img-small {
		display: inline-block;
	}

	.login-p2froots-logo {
		width: 100%;
	}
}

@media only screen and (min-device-width:1024px) and (max-device-width:1366px) and (orientation:portrait) {
	.project-header {
		padding-left: 79px;
	}

	.project-list {
		padding-left: 64px;
	}

	form.form-design {
		padding-bottom: 30px;
	}

	.user-dropdown ul.dropdown-menu {
		left: -5px;
	}

	.content-wrapper.background .myprojects .list-project ul li {
		margin: 0 2% 30px 0;
		max-width: 50%;
		width: 31%;
		float: left;
		flex: inherit;
	}

	.content-wrapper.background {
		margin-left: 81px;
	}

	ul.fixed-tabs {
		width: calc(100% - 64px);
		left: 64px;
	}

	.nav-pills:before {
		width: 100vw;
	}

	div.make-revision-btn .btn-secondary {
		padding: 6px 5px;
	}

	.project-info .card-body {
		height: 205px;
	}

	.bg {
		width: 100%;
		position: absolute;
		top: 0;
		padding-bottom: 40px;
	}

	.login-right {
		width: 100%;
		z-index: 99;
		text-align: center;
	}

	.login-screen,.reset-screen {
		margin: 15% auto 0 auto;
		padding: 5px 15px;
	}

	.welcome-deeproots {
		display: none;
	}

	.login-tab-header {
		display: block;
		padding: 6px 20px;
	}

	.language-changes {
		top: -52px;
		left: 30px;
		margin: inherit;
	}

	.login-tab-header img {
		width: 140px;
	}

	.login-screen p {
		line-height: normal;
		margin-bottom: 20px;
	}
}

@media (max-width:767px) {
	.project-list {
		padding: 0 0 32px 0;
		background: #fff;
		border-bottom: 10px solid #ddd;
	}

	.project-header {
		padding: 6px 15px;
	}

	.project-header .form-control {
		padding: 5px 12px!important;
		margin-top: 6px;
	}

	.project-header .form-group-sm .input-group .input-group-icon {
		margin-top: 6px;
		height: 33px;
	}

	.peoject-img-list {
		width: 100%;
	}

	.project-right-area {
		width: 100%;
		margin-top: 5px;
		padding: 0;
	}

	.project-para {
		height: auto;
	}

	.project-table-data {
		position: inherit;
		margin-top: 12px;
		display: block;
	}

	.project-header .search {
		width: 30%;
		margin: 1px 0 6px 0;
	}

	.project-table-data table.table {
		border: 1px solid #ddd;
	}

	.project-table-data .table tbody tr td {
		font-size: .8rem;
		padding: .45rem .6rem;
	}

	.form-box {
		padding: 10px 10px;
	}

	.form-group {
		margin: .5em 0 .5em;
	}

	.form-design label {
		margin-bottom: 3px;
		font-size: .8rem;
	}

	.form-design .form-control {
		padding: 8px 5px;
	}

	.form-design .form-box button.btn {
		height: 31px;
	}

	table.form-table td {
		padding: 4px .5rem;
	}

	.form-design .group-title {
		font-size: 1.1rem;
		margin-top: 0;
	}

	.partner-services {
		white-space: nowrap;
		overflow-x: auto;
	}

	.form-design .form-box.actions button.btn {
		box-sizing: border-box;
		margin: 0 10px 0 0!important;
		height: auto;
		font-size: .7rem;
		padding: 7px 8px;
	}

	.service-name {
		white-space: nowrap;
		margin-top: 5px;
		font-size: .8rem;
	}

	.partner-services-list .service-image {
		width: auto;
		padding: 10px 10px;
	}

	.partner-services-list .service-image img {
		height: 27px;
	}

	.partner-services .service-image {
		padding: 10px 10px;
	}

	.partner-services .service-name {
		font-size: .8rem;
	}

	.partner-services .service-image img {
		height: 35px;
	}

	.project-list .view-more {
		position: inherit;
		width: 96%;
		margin: 14px 2% 0 2%;
		border-radius: 3px;
	}

	.project-list .view-more span {
		transform: inherit;
		position: inherit;
		text-indent: inherit;
		padding: 5px 15px;
	}

	.project-header h3 {
		font-size: 1.4em!important;
	}

	.project-header .btn {
		float: right;
		padding: .2rem .9rem;
		margin: 5px 0 1px 0;
	}

	.project-list-box {
		margin-top: 120px;
	}

	.service-image {
		width: auto;
	}

	.firmenich-logo-center {
		display: none;
	}

	.form-design .form-box.actions {
		position: fixed;
		width: 100%;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: #CBE7F5;
		z-index: 9999;
		margin: 0;
		box-shadow: 0 0 7px #a2a2a2;
	}

	form.form-design {
		padding-bottom: 30px;
	}

	.user-dropdown ul.dropdown-menu {
		left: -5px;
	}

	.content-wrapper.background .myprojects .list-project ul li {
		margin: 0 0 30px 0;
		max-width: 100%;
		width: 100%;
		float: left;
		flex: inherit;
	}

	.content-wrapper.background {
		margin-left: 0;
	}

	.content-wrapper.background {
		margin: 127px 20px 40px 20px!important;
	}

	.snapshot-btn-align {
		text-align: left;
	}

	.stage-info h4.card-title {
		margin-bottom: 0;
	}

	.snapshot-btn-align button.btn.btn-secondary {
		margin: 5px 10px 10px 0;
	}

	.select-snap-v div {
		margin: 0!important;
	}

	.select-snap-v select {
		margin-bottom: 9px;
	}

	.project-header nav.breadcrumb li {
		font-size: .8rem;
	}

	.project-header nav.breadcrumb li a {
		font-size: .8rem;
	}

	div.content-wrap.no-search {
		margin-top: 120px!important;
	}

	.project-info-box .table td {
		padding: 3px 10px!important;
		font-size: 12px;
	}

	.snapshot-status {
		padding: 11px 12px!important;
	}

	.project-info .card-header {
		padding: 7px 14px;
	}

	.snapshot-status button.btn.btn-secondary {
		width: 100%;
		margin: 0 0 12px 0;
	}

	.snapshot-status div.col-sm-2.mr-1 {
		margin: 0!important;
	}

	.project-info-box .table {
		margin-bottom: 0;
	}

	.snapshot-status select.form-control {
		margin-bottom: 7px;
	}

	.user-dropdown .dropdown-toggle {
		padding: 18px 11px;
		font-size: 1rem;
	}

	.form-design .datatable-header {
		padding: 0 0 7px 0;
		margin: 0;
		text-align: left;
	}

	.print-projectinfo {
		text-align: left;
		margin: 0;
		padding: 6px 0 7px 0;
		text-align: left;
	}

	.form-design .datatable-header .btn {
		margin-bottom: 10px;
	}

	.table tbody tr td {
		padding: 2px 8px!important;
		font-size: 12px;
	}

	.form-design .fixed-actions {
		left: 0;
		width: 100%;
	}

	ul.fixed-tabs {
		left: 0;
		width: 100%;
		top: 90px;
	}

	.nav-pills:before {
		width: 100vw;
	}

	.project-info .card-body {
		height: auto;
		padding-bottom: 20px;
	}

	.project-info .card {
		margin-bottom: 15px;
	}

	.card-body {
		padding: 0;
	}

	.bg {
		width: 100%;
		position: absolute;
		top: 0;
		padding-bottom: 40px;
		background-image: url(../images/login-bg-plain.webp);
	}

	.login-right {
		width: 100%;
		z-index: 99;
		text-align: center;
	}

	.login-screen,.reset-screen {
		margin: 31% auto 0;
		width: 90%;
		padding: 5px 15px;
	}

	.language-changes {
		top: 6%;
	}

	.welcome-deeproots {
		display: none;
	}

	.login-tab-header {
		display: block;
		padding: 6px 20px;
	}

	.login-tab-header img {
		width: 140px;
	}

	.login-screen p {
		line-height: normal;
		margin-bottom: 20px;
	}

	.questionnaire-tabs .form-group {
		height: auto;
	}

	.farmer-profile-div .nav-pills li {
		width: auto;
	}

	.peoject-img-list a {
		display: none;
	}

	.dashboard-box {
		display: block;
	}

	.project-title {
		text-align: left;
		font-size: 1rem;
	}

	#mytable2 tr:nth-child(1) td {
		border-top: none!important;
	}

	.ngx-pagination {
		margin: 0;
		padding: 0;
	}

	.name-age {
		padding: 6px 0;
	}

	.datasheet-table {
		margin: 0 15px;
	}

	.prev-btn {
		left: 0;
	}

	ul.sub-tab-fixed {
		left: 26px;
	}

	.farmer-profile-div .nav-pills li a {
		padding: 6px 18px;
	}

	.farmer-profile-div .nav-pills {
		overflow-x: auto;
	}

	.msg-wizard {
		bottom: 64px;
	}

	.scroll-to-top {
		bottom: 62px!important;
	}

	div.view-profile {
		margin-top: 165px!important;
	}

	.view-profile-data h3 {
		margin-top: 0;
	}

	.error-msg {
		width: 100%;
		margin-top: 20px;
		text-align: center;
	}

	.error-img {
		width: 100%;
	}

	.error-template {
		margin-top: 100px;
	}

	.error-img img {
		width: 150px;
	}

	.error-template p {
		font-size: 1rem;
		line-height: 30px;
	}

	.nav-tabs.nav-tabs-secondary .nav-item .nav-link {
		padding: 6px 14px;
	}

	.ecosystem-location {
		width: 100%;
		height: auto;
	}

	.login-p2froots-img {
		display: none;
	}

	.login-p2froots-img-small {
		display: inline-block;
	}

	.toggler-small {
		height: 55px!important;
	}
}

.leftbtn,.rightbtn {
	background: rgba(0,0,0,.2);
	border: 0;
	font-size: 16px;
	font-weight: 700;
	position: relative;
	top: -3.4em;
}

.rightbtn {
	float: right;
}

:focus {
	outline: 0!important;
}

.table.table-one-line td {
	word-break: break-word;
}

@media (min-width:800px) {
	.leftbtn,.rightbtn {
		display: none;
	}
}

.slidedownsearch {
	padding-right: 15px;
}

@media only screen and (max-width:800px) {
	#resp-table table,#resp-table tbody,#resp-table td,#resp-table th,#resp-table thead,#resp-table tr {
		display: block;
		float: left;
		width: 100%;
	}

	#resp-table thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}

	#resp-table table tr:nth-child(odd) {
		background: #f2f2f2;
	}

	#resp-table td {
		border: none;
		border-bottom: 1px solid #eee;
		position: relative;
		padding-left: 50%;
		text-align: left;
	}

	#resp-table td:before {
		top: 6px;
		left: 6px;
		width: 45%;
		padding-right: 10px;
		text-align: left;
		font-weight: 700;
	}

	#resp-table td:before {
		content: attr(data-title);
	}

	.slidedownsearch {
		text-align: left!important;
	}

	.separate-card>div.input-group {
		width: 80%;
	}
}

@media only screen and (min-height:800px) {
	.content-wrapper {
		min-height: 432px;
	}
}

@media screen and (max-aspect-ratio:13/9) {
	div ul.fixed-tabs {
		left: 0;
		position: fixed;
		width: 100%;
	}

	ul.sub-tab-fixed {
		left: 0;
		position: fixed;
		margin-left: 0!important;
		margin-right: 0!important;
	}

	ul.fixed-tabs li {
		width: auto;
	}

	.prev-btn {
		position: fixed;
		left: 0;
		z-index: 9;
		height: 36px;
		line-height: 43px;
		border: none;
		padding: 0 9px;
		background-color: #eaeaea;
		box-shadow: 3px 1px 10px #656464;
	}

	.next-btn {
		position: fixed;
		right: 0;
		z-index: 9;
		height: 36px;
		line-height: 43px;
		border: none;
		padding: 0 9px;
		background-color: #eaeaea;
		box-shadow: 3px 1px 10px #656464;
	}

	.farmer-profile-div .nav-pills li a {
		padding: 8px 20px;
	}

	#pills-tab .nav-item a {
		padding: 8px 20px!important;
		margin-left: 25px;
	}

	.project-header {
		padding-left: 15px;
	}

	.profiling-screen .form-box {
		padding: 10px;
	}

	.project-header nav.breadcrumb {
		width: 75%;
	}
}

.separate-card {
	background: #f2f5f9;
	padding: 10px;
	width: 100%;
}

.separate-card:nth-child(2n) {
	background: #fff;
	padding: 10px;
}

.separate-card>div.input-group {
	width: 84%;
}

.region-card-box {
	width: 100%;
}

.region-card-inputbox {
	width: 85%;
	display: inline-block;
}

.region-card-delete {
	width: 14%;
	display: inline-block;
	padding: 0 0 0 4%;
}

.note-message {
	padding: 3px 0 3px 19px;
}

.project_est_weight_area {
	width: 35%!important;
	display: inline-block;
}

.markDefaultSelect>.multiselect-dropdown>.dropdown-list>ul.item2>li.multiselect-item-checkbox:nth-child(1) {
	cursor: not-allowed!important;
	pointer-events: none;
}

.markDefaultSelect>.multiselect-dropdown>.dropdown-list>ul.item2>.multiselect-item-checkbox:nth-child(1)>input[type="checkbox"]+div {
	color: #ccc;
}

.markDefaultSelect>.multiselect-dropdown>.dropdown-list>ul.item2>.multiselect-item-checkbox:nth-child(1)>input[type="checkbox"]:checked+div:before {
	background: #ccc3c3!important;
	border: 2px solid #ccc3c3!important;
}

.markDefaultSelect>.multiselect-dropdown>.dropdown-list>ul.item2>.multiselect-item-checkbox:nth-child(1)>input[type="checkbox"]+div:before {
	border: 2px solid #ccc;
}

.markDefaultSelect>.multiselect-dropdown>div>.dropdown-btn>.selected-item:nth-child(1) {
	cursor: not-allowed!important;
	pointer-events: none!important;
}

.markDefaultSelect>.multiselect-dropdown>div>.dropdown-btn>.selected-item:nth-child(1)>a {
	display: none!important;
}

input.form-control.ng-dirty.ng-touched.has-error.ng-invalid {
	border: 1px solid #ef001d;
}

.icons-indicators {
	width: 32px;
	height: 30px;
	padding: 2px;
}

.display-flex {
	display: inline-flex;
}

.icons-span {
	position: relative;
	top: 4px;
}

.pBottom40 {
	padding-bottom: 100px;
}

.form-box-gray {
	background: #CBE7F5;
}

.jumbotron-custom {
	float: left;
	background: 0 0;
	width: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 2rem 0 0 0;
	display: inline-block;
}

.chartbox {
	float: left;
	padding: 16px;
	width: 600px;
	background: #fff;
	border: 1px solid #cdcdcd;
	border-radius: 8px;
	position: relative;
}

.chartbox .title {
	padding: 0;
	margin: 0;
	font-size: 1.5rem;
	font-weight: 500;
}

.chartbox .morebtn {
	position: absolute;
	top: 0;
	right: 0;
	padding: 22px 16px;
	background: #f0f0f0;
	border-radius: 5px;
}

.chartbox .morebtn .bar {
	width: 30px;
	height: 3px;
	background: #666;
	display: block;
}

.chartbox .morebtn .bar:before {
	width: 30px;
	height: 3px;
	content: "";
	background: #666;
	position: absolute;
	top: 13px;
}

.chartbox .morebtn .bar:after {
	width: 30px;
	height: 3px;
	content: "";
	background: #666;
	position: absolute;
	bottom: 13px;
}

.view-action {
	float: right;
	width: 60px;
	margin-top: 7px;
}

.view-action span {
	padding: 10px;
}

.view-action i {
	color: #fff;
}

.btn-primary-blue {
	color: #fff;
	background-color: #30365a;
	min-width: auto;
	padding: 6px 12px;
	border-radius: 4px;
	border: 1px solid #727A06;
	text-transform: capitalize;
	margin: 15px 0 5px 0;
}

.btn-primary-blue:hover {
	background-color: #374077;
	color: #fff;
}

.btn.btn-default-gray {
	background: #f0f0f0;
	color: #30365a;
}

.btn.btn-default-gray:hover {
	background: #e5f1fa;
	color: #30365a;
}

.highchart-wrapper-box {
	width: 321px;
	margin: 10px;
	display: inline-block;
	vertical-align: bottom;
}

.highchart-wrapper-box .highchart-card {
	background: #fff;
	padding: 15px;
	border: 1px solid #4ca1e0;
	border-radius: 10px;
}

.highchart-wrapper-box .highchart-card.active,.highchart-wrapper-box:hover .highchart-card {
	background: #f1efef;
	box-shadow: 0 0 7px #666;
}

.highchart-wrapper-box .highchart-card.active .title {
	color: #30365a;
}

.highchart-wrapper-box .highchart-card .title {
	color: #4ca1e0;
	font-weight: 500;
	font-size: 1.5em;
	padding: 8px 0;
	height: 58px;
	overflow: hidden;
}

.highchart-wrapper-box .highchart-card .marker {
	color: #adadad;
	margin-bottom: 0;
	font-weight: 500;
	font-size: 1.1rem;
}

.highchart-wrapper-box .highchart-card .footer {
	display: block;
	min-height: 54px;
}

.highchart-wrapper-box .highchart-card .footer button {
	display: inline-block;
	margin-right: 10px;
}

.highchart-wrapper-box .multiselect-dropdown .disabled>span {
	background-color: #fff!important;
}

.highchart-wrapper-box .form-design .btn.btn-lg {
	padding: .05rem 2rem .4rem 2rem!important;
	font-size: 1.45rem!important;
	line-height: 1.5;
	border-radius: 0.3rem!important;
	vertical-align: middle!important;
	display: inline-block;
}

.highchart-wrapper-box .form-design button.btn.btn-success {
	border: 1px solid #B2BF00;
	background-color: #B2BF00;
	color: #fff;
}

.highchart-wrapper-box .reports-sec {
	margin: 20px;
	width: 100%;
}

.grid-content {
	overflow: hidden!important;
}

.chartsMainDiv {
	width: 100%;
}

.chartbox {
	width: 389px;
	margin: 1% 5px auto;
	font-family: "DM Sans", Verdana;
}

.mrzero {
	margin: 0;
}

.mrlrzero {
	margin-left: 0;
	margin-right: 0;
}

.mrlrone {
	margin-left: 1%;
	margin-right: 1%;
}

.pdzero {
	padding: 0;
}

.mrbottomten {
	margin-bottom: 15px;
}

.chartbox-add {
	width: 346px;
}

.dashboard-name {
	display: inline-block;
	padding: 0 2% 0 0;
	font-size: 1.3rem;
	color: #292929;
	font-weight: 700;
	margin-top: 8px;
	text-transform: uppercase;
	font-family: "DM Sans", Verdana;
	vertical-align: middle;
}

.dashboard-desc {
	font-size: 1rem;
	font-weight: 400;
	display: inline-block;
	margin-top: 1%;
}

.cp {
	cursor: pointer;
}

.disabledDiv {
	cursor: not-allowed!important;
}

.min-height-calc {
	min-height: calc(100vh - 100px);
}

.chartbox-view {
	width: 352px;
}

.mrlrfour {
	margin: 0 4px;
}

.highcharts-container {
	font-family: "DM Sans", Verdana;
	border: 1px solid #cdcdcd;
	border-radius: 8px;
}

.highcharts-container text {
	font-family: "DM Sans", Verdana;
}

* {
	font-family: "DM Sans", Verdana;
}

.chartBox-pdmr {
	padding: 10px 5px;
	margin-top: .5%;
	height: 300px;
}

.white-box {
	border-radius: 4px;
	background-color: #fff;
	box-sizing: border-box;
	padding: 10px 20px;
	box-shadow: 0 2px 13px rgb(5,0,194,.16);
}

.male-farmer {
	background: url(../images/male-farmer.svg) 0 0 no-repeat;
	padding: 20px 0 20px 40%;
	background-size: 50% auto;
	text-align: center;
	font-size: 36px;
	font-weight: 700;
}

.female-farmer {
	background: url(../images/female-farmer.svg) 0 0 no-repeat;
	padding: 20px 0 20px 40%;
	background-size: 50% auto;
	text-align: center;
	font-size: 36px;
	font-weight: 700;
}

.female-farmer .small,.male-farmer .small {
	font-size: 18px;
	position: relative;
	top: -3px;
}

.font-20 {
	font-size: 18px;
}

.font-24 {
	font-size: 24px;
}

.align-center {
	text-align: center;
}

.font-30 {
	font-size: 30px;
	font-weight: 700;
}

.font-30 .small {
	font-size: 18px;
}

img {
	max-width: 100%;
}

.flex-center {
	display: flex;
	align-items: center;
}

.flex {
	display: flex;
}

.down-red {
	position: absolute;
	background: url(../images/red-arrow.webp) 0 50% no-repeat;
	top: calc(50% - 12px);
	right: 0;
	padding-left: 17px;
}

.up-green {
	position: absolute;
	background: url(../images/up-arrow-green.webp) 0 50% no-repeat;
	top: calc(50% - 12px);
	right: 0;
	padding-left: 17px;
}

.height100 {
	height: 100%;
}

.height-48 {
	height: 48px;
}

.height-83 {
	height: 83px;
}

.border-bottom {
	border-bottom: 1px solid #404040;
}

#container1,#container2 {
	height: 50%;
}

.pdleft-two {
	padding-left: 2px;
}

.mrbtm-two {
	margin-bottom: 2%;
}

.fnt-size-common-number {
	font-size: 32px;
}

.pdleft-five {
	padding-left: 5px;
}

.pdleft-zero {
	padding-left: 0;
}

.pdtop-14 {
	padding-top: 14px;
}

.highcharts-exporting-group>g.highcharts-button.highcharts-button-normal:nth-child(2) {
	display: none;
}

text.highcharts-credits {
	display: none;
}

.dashboard-card-wrapper {
	width: 100%;
	padding: 15px;
	border: 1px solid #595a5d;
	border-radius: 5px;
	margin-bottom: 30px;
	position: relative;
	cursor: pointer;
}

.dashboard-card-wrapper:hover {
	background-color: #fafafa;
}

.dashboard-card-wrapper:hover>.dashboard-card-created {
	display: inline-block;
	bottom: 8px;
}

.dashboard-card-image {
	padding-top: 25px;
}

.dashboard-card-text {
	color: #4ca1e0;
	font-weight: 500;
	font-size: 1.4em;
	padding: 0 18px;
	height: 64px;
	align-items: center;
	display: flex;
	justify-content: center;
}

.dashboard-predefined {
	background-color: #fff;
}

.dashboard-self {
	background-color: #CBE7F5;
}

.dashboard-card-buttons {
	position: absolute;
	top: 10px;
	right: 10px;
}

.dashboard-card-buttons .edit-btn {
	width: 30px;
	justify-content: center;
}

.inline-flex {
	display: inline-flex;
}

.dashboard-card-label {
	position: absolute;
	left: 0;
	top: 0;
}

.white {
	color: #fff;
}

.dashboard-card-created {
	display: none;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	font-size: .7rem;
}

.canvas-container {
	position: relative;
}

canvas.canvas-div {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}

span.span-content {
	color: #555;
	display: block;
	line-height: 220px;
	text-align: center;
	width: 220px;
	font-family: sans-serif;
	font-size: 40px;
	font-weight: 100;
	margin-left: 5px;
}

.add-btn {
	position: relative;
	top: 4px;
}

.deletebtn {
	position: relative;
	top: 6px;
}

.setAsDefault-option {
	position: relative;
	top: 9px;
}

.runBtn {
	margin-top: 0!important;
}

.runBtn.btn.btn-primary:disabled {
	background-color: #727A06;
	border: 1px solid #727A06;
	color: #fff;
}

.runreport-btns {
	position: relative;
	top: 5px;
}

.ecosystem-location .highcharts-container {
	border: none;
}

.highcharts-exporting-group .highcharts-button {
	visibility: hidden;
}

.highcharts-exporting-group .highcharts-contextbutton {
	visibility: visible;
}

.no-border {
	border: none!important;
}

.value-box {
	height: 150px;
	font-size: 3rem;
	padding: 45px 0;
	color: #50c1bf;
	line-height: 50px;
}

.value-box span {
	background: url(../images/total.webp) top 0 left 0 no-repeat;
	background-size: auto 50px;
	padding-left: 60px;
}

.value-box-farmer strong {
	font-size: 1.1rem;
	font-weight: 300;
	line-height: 24px;
	color: #30365a;
}

.question-images {
	width: 200px;
	height: 200px;
}

.question-images img,.question-images-small img {
	border-radius: 50%;
	width: 100%;
	height: 100%;
}

.question-images-small img {
	background-color: #ccc;
}

.question-images-small {
	width: 80px;
	height: 90px;
	padding: 10px 10px 10px 0;
	float: left;
}

.question-component {
	position: relative;
	width: 100%;
	float: left;
}

.question-box {
	position: absolute;
	left: 150px;
	top: 20px;
	padding: 10px 20px 10px 70px;
	width: calc(100% - 400px);
}

.question-box-small {
	padding: 10px 0;
	width: calc(100% - 80px);
	float: right;
}

.question-box strong,.question-box-right strong {
	font-size: 18px;
	font-weight: 300;
}

.blue-big {
	color: #007bff;
	font-size: 30px;
}

.question-box-right {
	position: absolute;
	right: 150px;
	bottom: 20px;
	padding: 10px 70px 10px 20px;
	width: calc(100% - 400px);
}

.question-graphs-right {
	width: calc(100% - 250px);
	float: right;
}

.question-icons section {
	margin-right: 25px;
	display: inline-block;
	text-align: center;
}

.question-icons img {
	width: 30px;
}

.blue-medium {
	color: #007bff;
	font-size: 18px;
}

.highcharts-no-border .highcharts-container {
	border: none;
}

.repeater {
	width: 100%;
	float: left;
}

.vanilla-box {
	border-radius: 4px;
	background-color: #fff;
	box-sizing: border-box;
	padding: 15px 20px;
	box-shadow: 0 0 5px rgb(5,0,194,.16);
}

.image-rounded {
	border-radius: 50%;
	background: #faebd6;
}

.font-big {
	font-size: 30px;
}

.text-header {
	font-weight: 500;
	font-size: 18px;
}

.gray-30 {
	font-weight: 400;
	font-size: 30px;
	color: #616060;
	line-height: 30px;
}

.countOfFarmers {
	display: inline-block;
	font-size: 1.3rem;
	color: #292929;
	font-weight: 700;
	margin-top: 6px;
	text-transform: uppercase;
}

.highcharts-data-table table,.highcharts-figure {
	min-width: 310px;
	max-width: 800px;
	margin: 1em auto;
}

.highcharts-data-table table {
	font-family: Verdana,sans-serif;
	border-collapse: collapse;
	border: 1px solid #ebebeb;
	margin: 10px auto;
	text-align: center;
	width: 100%;
	max-width: 500px;
}

.highcharts-data-table caption {
	padding: 1em 0;
	font-size: 1.2em;
	color: #555;
}

.highcharts-data-table th {
	font-weight: 600;
	padding: .5em;
}

.highcharts-data-table caption,.highcharts-data-table td,.highcharts-data-table th {
	padding: .5em;
}

.highcharts-data-table thead tr,.highcharts-data-table tr:nth-child(even) {
	background: #f8f8f8;
}

.highcharts-data-table tr:hover {
	background: #f1f7ff;
}

.highcharts-data-table td {
	border: 1px solid #ccc!important;
}

.highcharts-data-table caption.highcharts-table-caption {
	border: 1px solid #ccc!important;
}

li.highcharts-menu-item {
	padding: .2em 1em!important;
}

.fp-gray-abs {
	color: #8c8888;
}

.height470 {
	height: 470px;
}

.blue-line {
	width: 100px;
	height: 5px;
	border-radius: 3px;
	background: #00d0ff;
	display: inline-block;
	line-height: 5px;
}

.white-border-box {
	position: absolute;
	top: 7px;
	left: 7px;
	width: calc(100% - 14px);
	height: calc(100% - 14px);
	border: 1px solid #fff;
}

.gray {
	color: #8c8c8c;
}

.height100-72 {
	height: calc(100% - 72px);
}

.padding10 {
	padding: 10px!important;
}

.padding10-tb {
	padding: 10px 0;
}

.gf-kpi-box {
	position: absolute;
	left: 0;
	bottom: 0;
	height: calc(100% - 72px);
	width: 100%;
	padding: 10px;
}

.gf-kpi-box-1 {
	position: absolute;
	left: 0;
	bottom: 0;
	height: calc(100% - 46px);
	width: 100%;
	padding: 10px;
}

.gf-kpi-box-2 {
	position: absolute;
	left: 0;
	bottom: 0;
	height: calc(100% - 0px);
	width: 100%;
	padding: 10px;
}

.blue-border-5 {
	position: relative;
}

.blue-border-5::before {
	content: "";
	display: block;
	position: absolute;
	bottom: -10px;
	width: 100px;
	left: 0;
	border-bottom: 5px solid #007bff;
	border-radius: 3px;
}

.bullet-dots {
	position: relative;
	padding: 5px 10px 5px 20px;
	margin-left: 10px;
	font-size: 1rem;
}

.bullet-dots::before {
	content: "";
	display: block;
	position: absolute;
	top: 11px;
	width: 12px;
	height: 12px;
	left: 0;
	background-color: #007bff;
	border-radius: 50%;
}

.font-1 {
	font-size: 1rem;
}

.blue {
	color: #48A2CF;
}

.white-bg {
	background-color: #fff;
}

.border-lt {
	border-left: 1px solid #dadde9;
	border-top: 1px solid #dadde9;
}

.border-rb {
	border-right: 1px solid #dadde9;
	border-bottom: 1px solid #dadde9;
}

.progress-pie-chart {
	width: 120px;
	height: 120px;
	border-radius: 50%;
	background-color: #e5e5e5;
	position: relative;
}

.progress-pie-chart.gt-50 {
	background-color: #1dc95b;
}

.ppc-progress {
	content: "";
	position: absolute;
	border-radius: 50%;
	left: calc(50% - 60px);
	top: calc(50% - 60px);
	width: 120px;
	height: 120px;
	clip: rect(0,120px,120px,60px);
}

.ppc-progress .ppc-progress-fill {
	content: "";
	position: absolute;
	border-radius: 50%;
	left: calc(50% - 60px);
	top: calc(50% - 60px);
	width: 120px;
	height: 120px;
	clip: rect(0,60px,120px,0);
	background: #1dc95b;
	transform: rotate(60deg);
}

.gt-50 .ppc-progress {
	clip: rect(0,60px,120px,0);
}

.gt-50 .ppc-progress .ppc-progress-fill {
	clip: rect(0,120px,120px,60px);
	background: #e5e5e5;
}

.ppc-percents {
	content: "";
	position: absolute;
	border-radius: 50%;
	left: calc(50% - 104.4px / 2);
	top: calc(50% - 104.4px / 2);
	width: 104.4px;
	height: 104.4px;
	background: #fff;
	text-align: center;
	display: table;
}

.ppc-percents span {
	display: block;
	font-size: 2.6em;
	font-weight: 500;
	color: #1dc95b;
}

.pcc-percents-wrapper {
	display: table-cell;
	vertical-align: middle;
}

.progress-pie-chart {
	margin: 0 auto;
}

.padding5-10 {
	padding: 5px 10px;
}

.padding0-10 {
	padding: 0 10px;
}

.padding5-20 {
	padding: 5px 20px;
}

.padding0-20 {
	padding: 0 20px;
}

.content-center {
	justify-content: center;
}

.icon-20 {
	height: 20px;
	width: 20px;
}

.male-farmer-small {
	background: url(../images/male-farmer.svg) 0 0 no-repeat;
	padding: 20px 0 20px 40%;
	background-size: 45% auto;
	text-align: center;
	font-size: 30px;
}

.female-farmer-small {
	background: url(../images/female-farmer.svg) 0 0 no-repeat;
	padding: 20px 0 20px 40%;
	background-size: 45% auto;
	text-align: center;
	font-size: 30px;
}

.mr-tp-1 {
	margin-top: 1%;
}

.highcharts-drillup-button .highcharts-button-box {
	width: 31px;
	height: 31px;
}

.highcharts-drillup-button .highcharts-button-box+text {
	fill: #fff!important;
	font-size: 19px;
	font-weight: 500!important;
	alignment-baseline: text-after-edge;
}

.mr-tp-1 {
	margin-top: 1%;
}

.semi-bold {
	font-weight: 500;
}

.profit-green {
	background: url(../images/profit.webp) 20px 10px no-repeat;
	padding: 20px 0 20px 100px;
	width: 100%;
	background-size: 70px auto;
}

.lost-red {
	background: url(../images/lost.webp) right 20px top 10px no-repeat;
	padding: 20px 100px 20px 0;
	width: 100%;
	background-size: 70px auto;
	text-align: right;
}

.profit-lost-grid {
	border: 1px solid #dadde9;
	border-radius: 4px;
}

.light-blue {
	background: #eef1ff;
}

.weight-400 {
	font-weight: 400;
}

.green {
	color: #8faf42;
}

.red {
	color: #d54b39;
}

.br-4-l {
	border-radius: 4px 0 0 4px;
}

.br-4-r {
	border-radius: 0 4px 4px 0;
}

.btn-noBg:active,.btn-noBg:focus {
	background-color: #fff!important;
	border-color: #B2BF00!important;
	box-shadow: none!important;
}

.dark-blue {
	color: #004281!important;
}

.inner-card {
	border: 1px solid #e6e5e5;
	border-radius: 4px;
	margin: 0 auto;
	width: calc(100% - 20px);
	height: 100%;
}

.inner-card-header {
	padding: 5px 15px;
	font-weight: 500;
	font-size: 1rem;
	background-color: rgba(0,0,0,.03);
	border-bottom: 1px solid rgba(0,0,0,.125);
}

.inner-card-body {
	padding: 10px 15px;
	height: calc(100% - 34px);
}

.border-right-light-blue {
	border-right: 1px solid #edeff7;
}

.source-rev {
	font-size: 14px!important;
	margin-bottom: 0!important;
}

.border-center {
	width: 100%;
	position: relative;
	height: 20px;
}

.border-center:before {
	content: "";
	position: absolute;
	bottom: 50%;
	border-bottom: 1px solid #edeff7;
	width: 100%;
	z-index: 0;
	left: 0;
}

.gray-center {
	background: #fff;
	position: absolute;
	top: calc(50% - 0.5rem);
	left: calc(50% - 24px);
	padding: 0 10px;
	color: #a2a0a0;
}

.small-indices {
	font-size: 0.78rem!important;
	font-weight: 500;
	padding: 0 7px!important;
}

.rev-compare-h3 {
	margin: 0;
	font-size: 1rem!important;
	margin-bottom: 10px;
}

.bold {
	font-weight: 700;
}

.capitalize {
	text-transform: uppercase;
}

ul.fixed-tabs-dq {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	margin: 0!important;
	z-index: 99;
	background-color: transparent!important;
	padding: 0;
}

ul.fixed-tabs-dq li {
	display: inline-block;
	list-style-type: none;
}

ul.fixed-tabs-dq li a {
	padding: 10px 40px!important;
	margin: 0!important;
	border-radius: 0!important;
	display: inline-block;
}

ul.fixed-tabs-dq li a:hover {
	background-color: #727A06!important;
	color: #fff;
	text-decoration: none;
}

ul.fixed-tabs-dq li a .utilicon {
	position: relative;
	top: 1px;
	display: none;
}

ul.fixed-tabs-dq li a .utilicon.font-1-2 {
	font-size: 1.2rem;
	top: 4px;
	line-height: 15px;
}

ul.fixed-tabs-dq li a.active .utilicon {
	display: inline-block;
}

ul.fixed-tabs-dq li a.active .utilicon:before {
	color: #fff;
}

ul.fixed-tabs-dq li a.active {
	color: #fff!important;
	background-color: #727A06!important;
	border-left: 1px solid #727A06;
	border-right: 1px solid #727A06;
	position: relative;
}

.project-header ol.breadcrumb {
	margin: 0;
	padding: 0;
}

ul.fixed-tabs-dq li a.active:after {
	top: 100%;
	left: 50%;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(98,119,91,0);
	border-top-color: #727A06;
	border-width: 10px;
	margin-left: -10px;
}

ul.sub-fixed-tabs-dq {
	position: absolute;
	top: 108px;
	left: 0;
	right: 0;
	width: 100%;
	margin: 0!important;
	z-index: 99;
	padding: 0;
}

ul.sub-fixed-tabs-dq li {
	display: inline-block;
	list-style-type: none;
}

ul.sub-fixed-tabs-dq li a {
	padding: 10px 40px!important;
	margin: 0!important;
	border-radius: 0!important;
	display: inline-block;
}

ul.sub-fixed-tabs-dq li a:hover {
	background-color: #b6daac!important;
	color: #fff;
	text-decoration: none;
}

ul.sub-fixed-tabs-dq li a .utilicon {
	position: relative;
	top: 1px;
	display: none;
}

ul.sub-fixed-tabs-dq li a .utilicon.font-1-2 {
	font-size: 1.2rem;
	top: 4px;
	line-height: 15px;
}

ul.sub-fixed-tabs-dq li a.active .utilicon {
	display: inline-block;
}

ul.sub-fixed-tabs-dq li a.active .utilicon:before {
	color: #fff;
}

ul.sub-fixed-tabs-dq li a.active {
	background-color: #b6daac!important;
	border-left: 1px solid #b6daac;
	border-right: 1px solid #b6daac;
	position: relative;
}

.farmer-profile-div .sub-fixed-tabs-dq {
	border-radius: 0;
	overflow: auto;
	display: block;
	white-space: nowrap;
}

.white-bg-fixed {
	position: fixed;
	top: 108px;
	left: 66px;
	background-color: #fff;
	z-index: 98;
	height: 104px;
	width: calc(100% - 64px);
}

.blue-anchor-bold {
	color: #3a6ea0;
	font-size: 1.1rem;
	text-decoration: none;
	font-weight: 500;
	margin: 0 20px;
}

.blue-anchor-bold-gray {
	color: #88888e;
	font-size: 1.1rem;
	text-decoration: none;
	font-weight: 500;
	margin: 0 20px;
}

.qedit-btn {
	background-color: #307297;
	color: #fff;
	border: 1px solid #307297;
	border-radius: 3px;
	padding: 0 3px;
	line-height: normal;
}

.qdelete-btn {
	border: 1px solid #d10000;
	color: #d20000;
	background-color: transparent;
	margin-left: 7px;
	border-radius: 3px;
	padding: 0 3px;
	line-height: normal;
}

.table-cross {
	width: 17px;
	height: 17px;
	display: inline-flex;
	margin-top: 8px;
}

.table-cross i {
	top: 1px;
	position: absolute;
	left: 3px;
}

.formula-icon {
	width: 7px;
	float: right;
	margin: 4px 3px 0 3px;
}

.display-logic-notice {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	background: #30365a;
	z-index: 1000;
	color: #fff;
	height: 100px;
	padding-top: 13px;
	opacity: .95;
}

.display-logic-key {
	border: 2px solid #3c52d6;
	border-radius: 5px;
	padding: 5px;
}

.display-logic-highlight {
	font-weight: 500;
}

.display-logic-action {
	font-weight: 400;
	text-decoration: none;
	cursor: pointer;
	color: #fff;
	padding: 0 10px;
	border: 1px solid #dadde9;
	border-radius: 4px;
	display: inline-block;
}

.display-logic-action:hover {
	background-color: #63775c;
}

.template-activate {
	float: right;
	margin-top: 7px;
}

.question-selected {
	padding: 3px 7px;
	background: #fff;
	color: #454545;
	border-radius: 4px;
	display: inline-block;
	margin-bottom: 5px;
}

.question-selected button {
	background: #727A06;
	color: #fff;
	border: none;
}

.deleted {
	color: #dfdbdb!important;
}

.deleted2 {
	color: #b1afaf!important;
}

.changes-breadcrumb {
	position: absolute;
	right: 0;
	top: 0;
	margin: 0;
	padding: 10px;
	z-index: 100;
}

.short-text {
	display: none;
}

.ellipsis {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	display: inline-block;
}

.formula-ellipsis {
	width: 135px;
	line-height: 15px;
	text-align: left;
	position: relative;
	top: 3px;
}

.formula-span span {
	display: inline-block;
}

.small-select {
	display: inline-block;
	width: 45%;
}

.modal-lg-firmenich {
	max-width: 85%;
}

.modal-md-firmenich {
	max-width: 60%;
}

.modal-backdrop {
	z-index: 1000;
}

.big-popup-close {
	right: 30px!important;
	top: 25px!important;
	padding: 7px 10px!important;
	border-radius: 15px;
}

.small-button {
	font-weight: 400;
	text-decoration: none;
	cursor: pointer;
	color: #3a6ea0;
	padding: 0 10px;
	border: 1px solid #dadde9;
	border-radius: 4px;
	display: inline-block;
	background-color: #fff;
}

.small-button:focus,.small-button:hover {
	background-color: #65b35c;
	color: #fff;
	text-decoration: none;
}

.big-button {
	font-weight: 400;
	text-decoration: none;
	cursor: pointer;
	color: #3a6ea0;
	padding: 5px 10px;
	border: 1px solid #dadde9;
	border-radius: 4px;
	display: inline-block;
	background-color: #fff;
}

.big-button:focus,.big-button:hover {
	background-color: #65B35C;
	color: #fff;
	text-decoration: none;
}

@media only screen and (max-width:768px) {
	.sd-mt-20 {
		margin-top: 20px;
	}

	.card-body {
		padding-bottom: 33px!important;
	}

	.white-bg-fixed {
		left: 0!important;
		width: 100%!important;
		padding: 0;
		top: 140px;
	}

	.sub-fixed-tabs-dq a {
		margin-left: 0!important;
		padding: 10px 40px!important;
	}

	.mb-breadcrumb ol {
		overflow-x: auto;
		white-space: nowrap;
		display: block;
	}

	.mb-breadcrumb ol li {
		display: inline-block;
	}

	.template-activate {
		float: none!important;
		margin-top: 0!important;
		margin-bottom: 10px;
	}

	.template-activate span {
		padding: 3px 7px!important;
	}

	.short-text {
		display: inline-block;
		padding: 0 7px!important;
		font-size: small;
	}

	.full-text {
		display: none;
	}

	.changes-breadcrumb {
		top: -45px;
	}
}

@media screen and (max-aspect-ratio:13/9) {
	.project-header nav.mb-breadcrumb {
		width: 100%;
		margin-bottom: 0;
	}
}

.children-details-table tr td {
	padding: 5px;
}

.posTopLeft {
	position: absolute;
	top: 1px;
	right: 10px;
}

.delete-dashboard {
	position: absolute;
	top: 7px;
	right: 8px;
	cursor: pointer;
}

.pRight200 {
	padding-right: 175px!important;
}

.no-box-shaow {
	box-shadow: none!important;
	background-color: #f0f0f0!important;
}

.line-ellipsis {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	overflow: hidden;
	line-height: 20px;
}

.template-card {
	position: relative;
	border: 1px solid #ebebeb;
	background-color: #faf9fc;
	cursor: pointer;
}

.template-card-selected {
	border: solid 1px green;
}

.template-card-selected::after,.template-card-selected::before {
	content: "";
	position: absolute;
	top: -1px;
	left: -1px;
	border-color: transparent;
	border-style: solid;
}

.template-card-selected::after {
	content: "\2713";
	font-size: 15px;
	line-height: 14px;
	font-weight: 700;
	color: #fff;
}

.template-card-selected::before {
	border-radius: 4px 0 0 0;
	border-width: 14px;
	border-left-color: green;
	border-top-color: green;
}

.popup-form-box {
	height: calc(100vh - 235px);
	overflow: auto;
	border-bottom: 1px solid #dadde9;
	padding: 10px;
}

.popup-form-box::-webkit-scrollbar {
	width: .4em;
}

.popup-form-box::-webkit-scrollbar,.popup-form-box::-webkit-scrollbar-thumb {
	overflow: visible;
	border-radius: 4px;
}

.popup-form-box::-webkit-scrollbar-thumb {
	background: rgba(0,0,0,.2);
}

.input-like-box {
	border: 1px solid #cdcdcd;
	padding: 3px 5px;
	border-radius: 3px;
	min-height: 39px;
}

.fixed-width-table-rows td,.fixed-width-table-rows th {
	min-width: 250px;
}

.fixed-width-table-rows td:last-child,.fixed-width-table-rows th:last-child {
	min-width: 100px;
}

.padding15-20 {
	padding: 15px 20px;
}

.content-flex-end {
	justify-content: flex-end;
}

.home-search-box {
	text-transform: capitalize;
	width: 250px!important;
	padding: 5px 10px!important;
}

.btn-blank {
	border: .0625em solid #bfbfbf!important;
	background: 0 0;
	padding: 8px!important;
	border-radius: 0 4px 4px 0;
}

.btn-blank:hover {
	background: #B2BF00;
	color: #fff!important;
}

.btn-back,.btn-home {
	width: 24px;
	height: 24px;
	border-radius: 100%;
	text-align: center;
	cursor: pointer;
	display: inline-block;
	position: relative;
}

.btn-back i,.btn-home i {
	width: 16px;
	height: 16px;
	position: absolute;
	left: 4px;
}

.btn-back i {
	color: #004372;
	font-size: 13px;
	top: 7px;
}

.btn-home i {
	color: #fff;
	font-size: 17px;
	top: 4px;
}

.gray-eye {
	height: 12px;
	float: left;
	margin-top: 5px;
	margin-right: 5px;
	color: #b7b7b7;
}

.btn-home img {
	width: 15px;
}

.breadcrumb-icon {
	display: inline-flex;
	margin-right: .7rem;
}

.breadcrumb-icon::after {
	display: inline-block;
	padding-left: .6rem;
	color: #004372;
	content: "|";
	font-weight: 400;
}

.login-p2f-logo {
	position: fixed;
	top: 10px;
	left: 10px;
}

.login-p2froots-logo {
	margin: 50px auto 40px;
	width: 90%;
	padding: 0 20px;
}

.login-fir-logo img,.login-p2f-logo img,.login-p2froots-logo img {
	width: 150px;
}

.login-fir-logo {
	position: fixed;
	top: 35px;
	left: calc(50% - 75px);
}

.form-control-login {
	width: 100%;
	display: block;
	border: none;
	background-color: transparent!important;
	padding: 6px 30px;
	border-bottom: 1px solid #949494;
}

.form-control-login:focus {
	border-bottom: 1px solid #0078d1;
	color: #0078d1;
}

input.form-control-login:-webkit-autofill,input.form-control-login:-webkit-autofill:active,input.form-control-login:-webkit-autofill:focus,input.form-control-login:-webkit-autofill:hover {
	-webkit-box-shadow: 0 0 0 30px #fff inset!important;
}

input.form-control-login:-webkit-autofill:focus {
	-webkit-text-fill-color: #0078d1!important;
}

.btn-login-blue {
	width: auto!important;
	background: #0078d1;
	border-radius: 18px!important;
	padding: 3px 16px!important;
	display: block;
	border: none;
	margin-top: 25px;
	color: #fff;
}

.btn-login-blue.disabled {
	background: #919292;
}

.btn-login-blank {
	width: auto!important;
	background: #e4eff7;
	border-radius: 18px!important;
	padding: 3px 16px!important;
	display: block;
	border: none;
	margin-top: 25px;
	color: #0078d1;
}

.language-select {
	border: none!important;
	font-weight: 400;
	padding: 0;
}

.language-select:focus,.language-select:hover {
	background-color: none!important;
	box-shadow: none!important;
}

.login-blue {
	color: #0078d1!important;
}

.country-flag {
	width: 22px;
	height: 14px;
	background-size: 22px 14px;
	background-repeat: no-repeat;
	margin-right: 5px;
	float: left;
	margin-top: 4px;
}

.en-flag {
	background-image: url(../images/us-flag.webp);
}

.es-flag {
	background-image: url(../images/es-flag.webp);
}

.fr-flag {
	background-image: url(../images/fr-flag.webp);
}

.mg-flag {
	background-image: url(../images/mg-flag.webp);
}

.ta-flag {
	background-image: url(../images/ta-flag.webp);
}

.hi-flag {
	background-image: url(../images/ta-flag.webp);
}

.zh-flag {
	background-image: url(../images/zh-flag.webp);
}

.id-flag {
	background-image: url(../images/id-flag.webp);
}

.new-lang-select {
	max-width: 90px;
	display: inline-block;
	font-weight: 500;
}

.language-dropdown-button {
	width: 123px;
	font-weight: 500;
	justify-content: left!important;
}

.language-dropdown-button .country-flag {
	margin-top: -1px!important;
	margin-right: 10px!important;
}

.language-dropdown-button .country-text {
	width: 81px;
	text-align: left;
}

.language-dropdown-button:hover {
	text-decoration: none!important;
}

.language-dropdown-menu {
	left: -11px!important;
	min-width: 145px;
	background-color: #f2f3f9;
	box-shadow: none!important;
	border: none;
	top: 5px!important;
}

.language-dropdown-menu a {
	padding: .25rem 0.75rem!important;
}

.template-used-val {
	font-weight: 500;
	margin-left: 5px;
	color: grey;
}

.template-used {
	font-weight: 500;
}

.right-separator {
	display: inline-block;
	width: .5px;
	height: 60%;
	top: 25%;
	right: 0;
	position: absolute;
	background-color: #c3c3c3;
}

.pass-bar {
	float: left;
}

.pass-bar ul {
	margin-left: 0!important;
}

.loading-text {
	font-size: 1.5rem!important;
}

.cookie-banner {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	background-color: rgb(48,54,90,.9);
	z-index: 100;
	color: #fff;
	padding: 20px 40px 20px 20px;
	line-height: 30px;
}

.cookie-banner a {
	color: #7daee2;
}

.cookie-banner-close {
	position: absolute;
	top: 50%;
	top: 50%;
	transform: translateY(-50%);
	right: 14px;
	color: #fff;
	font-weight: 700;
	font-size: 1.3rem;
}

.mt-35px {
	margin-top: 35px;
}

.view-button {
	border: none;
	background: 0 0;
	font-size: .78rem;
}

.btn-link {
	background: 0 0;
	border: none;
	color: #3d639f;
	text-decoration: underline;
}

.indices {
	width: 200px;
}

.indices span {
	text-transform: uppercase;
	display: inline-block;
	padding: 2px 7px;
	text-align: center;
	font-size: .9rem;
}

.edited,.indices span:nth-child(1) {
	background: #f0eada;
}

.added,.indices span:nth-child(2) {
	background: #dae1bf;
}

.deleted-q,.indices span:nth-child(3) {
	background: #ffe9b4;
}

.table-custom-one {
	width: 100%;
	border: 1px solid #ccc;
	border-top: none;
}

.table-custom-one thead {
	background: #dfe5eb;
}

.table-custom-one td,.table-custom-one th {
	border-top: 1px solid #ccc!important;
	border-right: 1px solid #ccc!important;
	border-bottom: none!important;
	border-left: none!important;
	padding: 4px 15px!important;
}

.table-custom-one td:last-child,.table-custom-one th:last-child {
	border-right: none!important;
}

.table-custom-one table {
	width: 100%;
}

.edited span.old {
	color: #ea5a0b;
	text-decoration: line-through;
}

.edited span.old:before {
	content: " [OLD]  ";
	color: #ea5a0b;
}

.table-custom-one td p {
	padding: 5px;
	display: block;
	width: 100%;
	margin: 0;
}

.table-custom-one .subsection {
	background-color: rgb(238 244 247);
}

.table-custom-one .qTable {
	background: #f2f2f2;
}

.table-custom-one .badge.badge-info {
	color: #fff;
	background: #004281;
}

.compare-answer-table-wrapper {
	width: 100%;
	background: #f0f4fa;
	padding: 1%;
}

.compare-answer-table-wrapper .compare-answer-table {
	width: 98%;
}

.compare-answer-table-wrapper .compare-answer-table tr {
	background: #fff;
}

.compare-answer-table-wrapper .compare-answer-table tr td {
	padding: 5px 9px;
	border: 3px solid #f0f4fa;
}

.compare-answer-table-wrapper .compare-answer-table tr.subsection {
	background: 0 0!important;
}

.compare-answer-table-wrapper .compare-answer-table tr.subsection td {
	color: #737373;
	font-size: 1.1rem;
	padding: 10px 9px;
}

.result-card {
	background: #fff;
	box-shadow: 0 0 5px #ccc;
	border: 1px solid #ccc;
	position: absolute;
	width: 100%;
	top: 45px;
	z-index: 9;
}

.result-card p {
	padding: 10px;
}

.inner-card {
	border: 1px solid #e6e5e5;
	border-radius: 4px;
	margin: 0 auto;
	width: calc(100% - 20px);
	height: 100%;
}

.inner-card-header {
	padding: 5px 15px;
	font-weight: 500;
	font-size: 1rem;
	background-color: rgba(0,0,0,.03);
	border-bottom: 1px solid rgba(0,0,0,.125);
}

.inner-card-body {
	padding: 10px 15px;
	height: calc(100% - 34px);
}

.source-rev {
	font-size: 14px!important;
	margin-bottom: 0!important;
}

.border-center {
	width: 100%;
	position: relative;
	height: 20px;
}

.border-center:before {
	content: "";
	position: absolute;
	bottom: 50%;
	border-bottom: 1px solid #edeff7;
	width: 100%;
	z-index: 0;
	left: 0;
}

.gray-center {
	background: #fff;
	position: absolute;
	top: calc(50% - 0.5rem);
	left: calc(50% - 24px);
	padding: 0 10px;
	color: #a2a0a0;
}

.card-rev-height {
	height: calc(100% - 20px);
}

.small-indices {
	font-size: 0.78rem!important;
	font-weight: 500;
	padding: 0 7px!important;
}

.font-1-1 {
	font-size: 1.1rem!important;
}

.source-answer-area,.target-answer-area {
	width: 29%;
	float: right;
	padding: 7px 7px 7px 20px;
}

.bg-none {
	background-color: #fff!important;
}

.fix-pos-numbers {
	position: fixed;
	top: 119px;
	left: 102px;
	background-color: #fff;
	width: 90%;
}

.fixed-pos-design {
	position: fixed;
	top: 119px;
	left: 102px;
	background-color: #fff;
	width: 90%;
}

.farmer-dropdown {
	position: fixed;
	top: 61px;
	right: 10px;
	z-index: 100;
	width: 250px;
}

.farmer-dropdown .multiselect-dropdown .dropdown-btn {
	padding: 1px 10px!important;
}

.farmer-dropdown .multiselect-dropdown .selected-item a,.farmer-name-nmd .multiselect-dropdown .selected-item a {
	display: none;
}

.farmer-dropdown .multiselect-dropdown .selected-item,.farmer-name-nmd .multiselect-dropdown .selected-item {
	background: 0 0!important;
	color: #454545!important;
	padding: 0!important;
	margin-bottom: 0!important;
	border: none!important;
}

.farmer-dropdown .multiselect-dropdown .selected-item:hover,.farmer-name-nmd .multiselect-dropdown .selected-item:hover {
	border: none!important;
	box-shadow: none!important;
}

.farmer-dropdown .multiselect-dropdown .dropdown-btn:focus,.farmer-dropdown .multiselect-dropdown .dropdown-btn:hover {
	background-color: #7ea47e;
	color: #fff!important;
	text-decoration: none!important;
}

.farmer-dropdown .multiselect-dropdown .dropdown-btn:focus .selected-item,.farmer-dropdown .multiselect-dropdown .dropdown-btn:hover .selected-item {
	color: #fff!important;
}

.caching {
	padding: 2px 0 2px 23px;
	width: 100%;
	color: #E67F7C;
}

.caching-completed {
	background: url(../images/caching-done.png) 0 0 no-repeat;
	padding: 1px 0 2px 26px;
	width: 100%;
	background-size: 20px auto;
	color: #2ccc72;
}

.caching-halted {
	padding: 2px 0 2px 3px;
	width: 100%;
	color: #e96262;
}

.confirm-answer {
	margin: 6px 0 0 13px;
	font-size: 25px;
	display: inline-flex;
	color: #ccc!important;
}

.answer-confirmed {
	color: #727A06!important;
}

.answer-na {
	width: 25px;
	height: 25px;
	border: 1.5px solid #ccc;
	border-radius: 50px;
	font-size: 10px;
	font-weight: 500;
	margin: 6px 0px 0px 15px;
    /* float: right; */
	padding-left: 3px;
	color: #ccc!important;
	display: inline-flex;
	vertical-align: top;
    /* background-image:url("/images/block.png");
    
    background-size:100% 100%;
    background-position:center; */;
}

.answer-not-confirmed {
	color: #FFAA4D!important;
	border-color: #FFAA4D;
}

.black-button {
	font-weight: 400;
	text-decoration: none;
	cursor: pointer;
	color: #fff;
	padding: 0 10px;
	border: 1px solid #000;
	border-radius: 15px;
	display: inline-block;
	background-color: #000;
	text-transform: uppercase;
}
.loader {
	border: 1px solid #fff;
	border-radius: 50%;
	border-top: 1px solid #E67F7C;
	border-right: 1px solid #E67F7C;
	border-bottom: 1px solid #E67F7C;
	width: 15px!important;
	height: 15px!important;
	-webkit-animation: spin 2s linear infinite; /* Safari */
	animation: spin 2s linear infinite;
	display: inline-block;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
	0% { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
  }

  .blank-button {
	border: none;
    background: transparent;
    padding-right: 20px;
  }
  .blank-button img {
	width:20px;
  }
  #refDoc .html {
    height: 683px;
  }
 
  .download-image {
    position: absolute;
    top: 7px;
    right: 10px;
}

.text-area {
    width:300px;
    height: 80px;
    position: absolute;
    top: 0px;
    left: 0px;
	background-color: #fff;
    border: 1px solid #cdcdcd;
    border-radius: 3px;
    padding: 8px 12px;
	z-index: 99;
}
.text-area:focus {
	border-color: #61765b!important;
	box-shadow: 0 0 5px #91ca7f!important;
}