@font-face {
	font-family: 'DINPro';
	font-weight: 300;
	font-style: normal;
	src: url('./DINPro-Light.eot');
	src: url('./DINPro-Light.eot?#iefix') format('embedded-opentype'),
			 url('./DINPro-Light.woff2') format('woff2'),
			 url('./DINPro-Light.woff') format('woff'),
			 url('./DINPro-Light.ttf') format('truetype'),
			 url('./DINPro-Light.svg#dinpro_light') format('svg');
}
@font-face {
	font-family: 'DINPro';
	font-weight: 300;
	font-style: italic;
	src: url('./DINPro-LightItalic.eot');
	src: url('./DINPro-LightItalic.eot?#iefix') format('embedded-opentype'),
			 url('./DINPro-LightItalic.woff2') format('woff2'),
			 url('./DINPro-LightItalic.woff') format('woff'),
			 url('./DINPro-LightItalic.ttf') format('truetype'),
			 url('./DINPro-LightItalic.svg#dinpro_light_italic') format('svg');
}
@font-face {
	font-family: 'DINPro';
	font-weight: 400;
	font-style: normal;
	src: url('./DINPro-Regular.eot');
	src: url('./DINPro-Regular.eot?#iefix') format('embedded-opentype'),
			 url('./DINPro-Regular.woff2') format('woff2'),
			 url('./DINPro-Regular.woff') format('woff'),
			 url('./DINPro-Regular.ttf') format('truetype'),
			 url('./DINPro-Regular.svg#dinpro_regular') format('svg');
}
@font-face {
	font-family: 'DINPro';
	font-weight: 400;
	font-style: italic;
	src: url('./DINPro-RegularItalic.eot');
	src: url('./DINPro-RegularItalic.eot?#iefix') format('embedded-opentype'),
			 url('./DINPro-RegularItalic.woff2') format('woff2'),
			 url('./DINPro-RegularItalic.woff') format('woff'),
			 url('./DINPro-RegularItalic.ttf') format('truetype'),
			 url('./DINPro-RegularItalic.svg#dinpro_regular_italic') format('svg');
}
@font-face {
	font-family: 'DINPro';
	font-weight: 500;
	font-style: normal;
	src: url('./DINPro-Medium.eot');
	src: url('./DINPro-Medium.eot?#iefix') format('embedded-opentype'),
			 url('./DINPro-Medium.woff2') format('woff2'),
			 url('./DINPro-Medium.woff') format('woff'),
			 url('./DINPro-Medium.ttf') format('truetype'),
			 url('./DINPro-Medium.svg#dinpro_medium') format('svg');
}
@font-face {
	font-family: 'DINPro';
	font-weight: 500;
	font-style: italic;
	src: url('./DINPro-MediumItalic.eot');
	src: url('./DINPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
			 url('./DINPro-MediumItalic.woff2') format('woff2'),
			 url('./DINPro-MediumItalic.woff') format('woff'),
			 url('./DINPro-MediumItalic.ttf') format('truetype'),
			 url('./DINPro-MediumItalic.svg#dinpro_medium_italic') format('svg');
}
@font-face {
	font-family: 'DINPro';
	font-weight: 700;
	font-style: normal;
	src: url('./DINPro-Bold.eot');
	src: url('./DINPro-Bold.eot?#iefix') format('embedded-opentype'),
			 url('./DINPro-Bold.woff2') format('woff2'),
			 url('./DINPro-Bold.woff') format('woff'),
			 url('./DINPro-Bold.ttf') format('truetype'),
			 url('./DINPro-Bold.svg#dinpro_bold') format('svg');
}
@font-face {
	font-family: 'DINPro';
	font-weight: 700;
	font-style: italic;
	src: url('./DINPro-BoldItalic.eot');
	src: url('./DINPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
			 url('./DINPro-BoldItalic.woff2') format('woff2'),
			 url('./DINPro-BoldItalic.woff') format('woff'),
			 url('./DINPro-BoldItalic.ttf') format('truetype'),
			 url('./DINPro-BoldItalic.svg#dinpro_bold_italic') format('svg');
}
@font-face {
	font-family: 'DINPro';
	font-weight: 700;
	font-style: normal;
	src: url('./DINPro-Black.eot');
	src: url('./DINPro-Black.eot?#iefix') format('embedded-opentype'),
			 url('./DINPro-Black.woff2') format('woff2'),
			 url('./DINPro-Black.woff') format('woff'),
			 url('./DINPro-Black.ttf') format('truetype'),
			 url('./DINPro-Black.svg#dinpro_black') format('svg');
}
@font-face {
	font-family: 'DINPro';
	font-weight: 700;
	font-style: italic;
	src: url('./DINPro-BlackItalic.eot');
	src: url('./DINPro-BlackItalic.eot?#iefix') format('embedded-opentype'),
			 url('./DINPro-BlackItalic.woff2') format('woff2'),
			 url('./DINPro-BlackItalic.woff') format('woff'),
			 url('./DINPro-BlackItalic.ttf') format('truetype'),
			 url('./DINPro-BlackItalic.svg#dinpro_black_italic') format('svg');
}
